/**======================================================================
=========================================================================
Template Name: Berry - Bootstrap Admin Template
Author: codedthemes
Support: https://codedthemes.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
body {
  font-feature-settings: "salt";
}

:root {
  --bs-body-bg: #eceff1;
  --bs-body-bg-rgb: 236, 239, 241;
  --pc-heading-color: #343a40;
  --pc-active-background: #e9ecef;
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #616161;
  --pc-sidebar-color-rgb: 57, 70, 95;
  --pc-sidebar-submenu-border-color: var(--bs-gray-300);
  --pc-sidebar-active-color: #6610f2;
  --pc-sidebar-active-color-rgb: 102, 16, 242;
  --pc-sidebar-shadow: none;
  --pc-sidebar-caption-color: #212121;
  --pc-sidebar-border: none;
  --pc-header-background: #fff;
  --pc-header-color: #616161;
  --pc-header-shadow: none;
  --pc-card-box-shadow: none;
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #111936;
}

[data-pc-sidebar-theme=dark] {
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
}

[data-pc-preset=preset-1] {
  --pc-sidebar-active-color: #673ab7;
  --bs-blue: #2196f3;
  --bs-primary: #2196f3;
  --bs-primary-rgb: 33, 150, 243;
  --bs-primary-light: #e9f5fe;
  --bs-secondary: #673ab7;
  --bs-secondary-rgb: 103, 58, 183;
  --bs-secondary-light: #f0ebf8;
  --bs-link-color: #2196f3;
  --bs-link-color-rgb: 33, 150, 243;
  --bs-link-hover-color: #1a78c2;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-secondary, $link-shade-percentage));
  --dt-row-selected: 33, 150, 243;
  --bs-body-bg: #eef2f6;
}
[data-pc-preset=preset-1] .bg-primary-dark {
  background: #1c76da;
  color: #1c76da;
}
[data-pc-preset=preset-1] .bg-secondary-dark {
  background: #542ca7;
  color: #542ca7;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-1] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-1] .pc-sidebar .pc-item:hover > .pc-link {
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-1] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-1] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-1] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-1] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-1] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-1] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #673ab7;
}
[data-pc-preset=preset-1] .btn-link {
  --bs-btn-color: #2196f3;
  --bs-btn-hover-color: #1a78c2;
  --bs-btn-active-color: #1a78c2;
}
[data-pc-preset=preset-1] .accordion {
  --bs-accordion-btn-focus-border-color: #2196f3;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
  --bs-accordion-active-color: #2196f3;
  --bs-accordion-active-bg: #e9f5fe;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232196f3'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .list-group {
  --bs-list-group-active-bg: #2196f3;
  --bs-list-group-active-border-color: #2196f3;
}
[data-pc-preset=preset-1] .nav {
  --bs-nav-link-hover-color: #1a78c2;
}
[data-pc-preset=preset-1] .nav-pills {
  --bs-nav-pills-link-active-bg: #2196f3;
}
[data-pc-preset=preset-1] .pagination {
  --bs-pagination-hover-color: #1a78c2;
  --bs-pagination-focus-color: #1a78c2;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
  --bs-pagination-active-bg: #2196f3;
  --bs-pagination-active-border-color: #2196f3;
}
[data-pc-preset=preset-1] .progress {
  --bs-progress-bar-bg: #2196f3;
}
[data-pc-preset=preset-1] .slider-selection {
  background-image: linear-gradient(to bottom, #9acffa 0, #9acffa 100%);
}
[data-pc-preset=preset-1] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #82c4f8 0, #82c4f8 100%);
}
[data-pc-preset=preset-1] .swal-button:not([disabled]):hover {
  background-color: #0d8aee;
}
[data-pc-preset=preset-1] .swal-button:active {
  background-color: #0d8aee;
}
[data-pc-preset=preset-1] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(33, 150, 243, 0.29);
}
[data-pc-preset=preset-1] .swal-content__input:focus {
  border-color: rgba(33, 150, 243, 0.29);
}
[data-pc-preset=preset-1] .swal-content__textarea:focus {
  border-color: rgba(33, 150, 243, 0.29);
}
[data-pc-preset=preset-1] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(33, 150, 243, 0.4) !important;
}
[data-pc-preset=preset-1] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #82c4f8 0, #82c4f8 100%);
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary {
  background: #d3eafd;
  color: #2196f3;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary > i {
  color: #2196f3;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary:hover {
  background: #2196f3;
  color: #fff;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-1] .bg-light-primary {
  background: #d3eafd;
  color: #2196f3;
}
[data-pc-preset=preset-1] .link-primary {
  color: #2196f3 !important;
}
[data-pc-preset=preset-1] .link-primary:hover, [data-pc-preset=preset-1] .link-primary:focus {
  color: #1a78c2 !important;
}
[data-pc-preset=preset-1] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2196f3;
  --bs-btn-border-color: #2196f3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1c80cf;
  --bs-btn-hover-border-color: #1a78c2;
  --bs-btn-focus-shadow-rgb: 66, 166, 245;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1a78c2;
  --bs-btn-active-border-color: #1971b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2196f3;
  --bs-btn-disabled-border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-outline-primary {
  --bs-btn-color: #2196f3;
  --bs-btn-border-color: #2196f3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2196f3;
  --bs-btn-hover-border-color: #2196f3;
  --bs-btn-focus-shadow-rgb: 33, 150, 243;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2196f3;
  --bs-btn-active-border-color: #2196f3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2196f3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2196f3;
  --bs-gradient: none;
}
[data-pc-preset=preset-1] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(33, 150, 243, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-1] .alert-primary {
  --bs-alert-color: #145a92;
  --bs-alert-bg: #d3eafd;
  --bs-alert-border-color: #bce0fb;
  --bs-alert-link-color: #104875;
}
[data-pc-preset=preset-1] .list-group-item-primary {
  color: #2196f3;
  background-color: #d3eafd;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:checked {
  border-color: #2196f3;
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: #d3eafd;
  background-color: #d3eafd;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232196f3' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232196f3'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232196f3'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .btn-light-primary {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #2196f3;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-link-primary:hover {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-preset=preset-1] .btn-link-primary:focus {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .material-icons-two-tone.text-primary {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #2196f3;
  --bs-table-border-color: #37a1f4;
  --bs-table-striped-bg: #2c9bf4;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #37a1f4;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #2598f3;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary {
  background: #e1d8f1;
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary > i {
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #673ab7;
  color: #fff;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-1] .bg-light-secondary {
  background: #e1d8f1;
  color: #673ab7;
}
[data-pc-preset=preset-1] .link-secondary {
  color: #673ab7 !important;
}
[data-pc-preset=preset-1] .link-secondary:hover, [data-pc-preset=preset-1] .link-secondary:focus {
  color: #522e92 !important;
}
[data-pc-preset=preset-1] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #673ab7;
  --bs-btn-border-color: #673ab7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #58319c;
  --bs-btn-hover-border-color: #522e92;
  --bs-btn-focus-shadow-rgb: 126, 88, 194;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #522e92;
  --bs-btn-active-border-color: #4d2c89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #673ab7;
  --bs-btn-disabled-border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-outline-secondary {
  --bs-btn-color: #673ab7;
  --bs-btn-border-color: #673ab7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #673ab7;
  --bs-btn-hover-border-color: #673ab7;
  --bs-btn-focus-shadow-rgb: 103, 58, 183;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #673ab7;
  --bs-btn-active-border-color: #673ab7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #673ab7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #673ab7;
  --bs-gradient: none;
}
[data-pc-preset=preset-1] .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(33, 150, 243, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-1] .alert-secondary {
  --bs-alert-color: #3e236e;
  --bs-alert-bg: #e1d8f1;
  --bs-alert-border-color: #d1c4e9;
  --bs-alert-link-color: #321c58;
}
[data-pc-preset=preset-1] .list-group-item-secondary {
  color: #673ab7;
  background-color: #e1d8f1;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-secondary:checked {
  border-color: #673ab7;
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #e1d8f1;
  background-color: #e1d8f1;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23673ab7' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .btn-light-secondary {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-light-secondary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary:hover {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-secondary.focus, [data-pc-preset=preset-1] .btn-light-secondary:focus {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-secondary.dropdown-toggle {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-1] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-secondary {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-link-secondary {
  background: transparent;
  color: #673ab7;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-secondary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-link-secondary:hover {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-link-secondary.focus, [data-pc-preset=preset-1] .btn-link-secondary:focus {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-secondary.dropdown-toggle {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-secondary {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .material-icons-two-tone.text-secondary {
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .table-secondary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #673ab7;
  --bs-table-border-color: #764ebe;
  --bs-table-striped-bg: #6f44bb;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #764ebe;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #6a3eb8;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-2] {
  --pc-sidebar-active-color: #009688;
  --bs-blue: #607d8b;
  --bs-primary: #607d8b;
  --bs-primary-rgb: 96, 125, 139;
  --bs-primary-light: #eff2f3;
  --bs-secondary: #009688;
  --bs-secondary-rgb: 0, 150, 136;
  --bs-secondary-light: #e6f5f3;
  --bs-link-color: #607d8b;
  --bs-link-color-rgb: 96, 125, 139;
  --bs-link-hover-color: #4d646f;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-secondary, $link-shade-percentage));
  --dt-row-selected: 96, 125, 139;
  --bs-body-bg: #eef2f6;
}
[data-pc-preset=preset-2] .bg-primary-dark {
  background: #4e6a78;
  color: #4e6a78;
}
[data-pc-preset=preset-2] .bg-secondary-dark {
  background: #008375;
  color: #008375;
}
[data-pc-preset=preset-2] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-2] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-2] .pc-sidebar .pc-item:hover > .pc-link {
  color: #009688;
}
[data-pc-preset=preset-2] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-2] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-2] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-2] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-2] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-2] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #009688;
}
[data-pc-preset=preset-2] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-2] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #009688;
}
[data-pc-preset=preset-2] .btn-link {
  --bs-btn-color: #607d8b;
  --bs-btn-hover-color: #4d646f;
  --bs-btn-active-color: #4d646f;
}
[data-pc-preset=preset-2] .accordion {
  --bs-accordion-btn-focus-border-color: #607d8b;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(96, 125, 139, 0.25);
  --bs-accordion-active-color: #607d8b;
  --bs-accordion-active-bg: #eff2f3;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23607d8b'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .list-group {
  --bs-list-group-active-bg: #607d8b;
  --bs-list-group-active-border-color: #607d8b;
}
[data-pc-preset=preset-2] .nav {
  --bs-nav-link-hover-color: #4d646f;
}
[data-pc-preset=preset-2] .nav-pills {
  --bs-nav-pills-link-active-bg: #607d8b;
}
[data-pc-preset=preset-2] .pagination {
  --bs-pagination-hover-color: #4d646f;
  --bs-pagination-focus-color: #4d646f;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(96, 125, 139, 0.25);
  --bs-pagination-active-bg: #607d8b;
  --bs-pagination-active-border-color: #607d8b;
}
[data-pc-preset=preset-2] .progress {
  --bs-progress-bar-bg: #607d8b;
}
[data-pc-preset=preset-2] .slider-selection {
  background-image: linear-gradient(to bottom, #a8bac3 0, #a8bac3 100%);
}
[data-pc-preset=preset-2] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #99aeb8 0, #99aeb8 100%);
}
[data-pc-preset=preset-2] .swal-button:not([disabled]):hover {
  background-color: #566f7c;
}
[data-pc-preset=preset-2] .swal-button:active {
  background-color: #566f7c;
}
[data-pc-preset=preset-2] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(96, 125, 139, 0.29);
}
[data-pc-preset=preset-2] .swal-content__input:focus {
  border-color: rgba(96, 125, 139, 0.29);
}
[data-pc-preset=preset-2] .swal-content__textarea:focus {
  border-color: rgba(96, 125, 139, 0.29);
}
[data-pc-preset=preset-2] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(96, 125, 139, 0.4) !important;
}
[data-pc-preset=preset-2] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #99aeb8 0, #99aeb8 100%);
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-primary {
  background: #dfe5e8;
  color: #607d8b;
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-primary > i {
  color: #607d8b;
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-primary:hover {
  background: #607d8b;
  color: #fff;
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-2] .bg-light-primary {
  background: #dfe5e8;
  color: #607d8b;
}
[data-pc-preset=preset-2] .link-primary {
  color: #607d8b !important;
}
[data-pc-preset=preset-2] .link-primary:hover, [data-pc-preset=preset-2] .link-primary:focus {
  color: #4d646f !important;
}
[data-pc-preset=preset-2] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #607d8b;
  --bs-btn-border-color: #607d8b;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #526a76;
  --bs-btn-hover-border-color: #4d646f;
  --bs-btn-focus-shadow-rgb: 120, 145, 156;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d646f;
  --bs-btn-active-border-color: #485e68;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #607d8b;
  --bs-btn-disabled-border-color: #607d8b;
}
[data-pc-preset=preset-2] .btn-outline-primary {
  --bs-btn-color: #607d8b;
  --bs-btn-border-color: #607d8b;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #607d8b;
  --bs-btn-hover-border-color: #607d8b;
  --bs-btn-focus-shadow-rgb: 96, 125, 139;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #607d8b;
  --bs-btn-active-border-color: #607d8b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #607d8b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #607d8b;
  --bs-gradient: none;
}
[data-pc-preset=preset-2] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(96, 125, 139, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-2] .alert-primary {
  --bs-alert-color: #3a4b53;
  --bs-alert-bg: #dfe5e8;
  --bs-alert-border-color: #cfd8dc;
  --bs-alert-link-color: #2e3c42;
}
[data-pc-preset=preset-2] .list-group-item-primary {
  color: #607d8b;
  background-color: #dfe5e8;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:checked {
  border-color: #607d8b;
  background-color: #607d8b;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: #dfe5e8;
  background-color: #dfe5e8;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23607d8b' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23607d8b'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(96, 125, 139, 0.25);
  border-color: #607d8b;
}
[data-pc-preset=preset-2] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23607d8b'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .btn-light-primary {
  background: #dfe5e8;
  color: #607d8b;
  border-color: #dfe5e8;
}
[data-pc-preset=preset-2] .btn-light-primary .material-icons-two-tone {
  background-color: #607d8b;
}
[data-pc-preset=preset-2] .btn-light-primary:hover {
  background: #607d8b;
  color: #fff;
  border-color: #607d8b;
}
[data-pc-preset=preset-2] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-2] .btn-light-primary.focus, [data-pc-preset=preset-2] .btn-light-primary:focus {
  background: #607d8b;
  color: #fff;
  border-color: #607d8b;
}
[data-pc-preset=preset-2] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-2] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle {
  background: #607d8b;
  color: #fff;
  border-color: #607d8b;
}
[data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-light-primary {
  background: #607d8b;
  color: #fff;
  border-color: #607d8b;
}
[data-pc-preset=preset-2] .btn-link-primary {
  background: transparent;
  color: #607d8b;
  border-color: transparent;
}
[data-pc-preset=preset-2] .btn-link-primary .material-icons-two-tone {
  background-color: #607d8b;
}
[data-pc-preset=preset-2] .btn-link-primary:hover {
  background: #dfe5e8;
  color: #607d8b;
  border-color: #dfe5e8;
}
[data-pc-preset=preset-2] .btn-link-primary.focus, [data-pc-preset=preset-2] .btn-link-primary:focus {
  background: #dfe5e8;
  color: #607d8b;
  border-color: #dfe5e8;
}
[data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-link-primary.dropdown-toggle {
  background: #dfe5e8;
  color: #607d8b;
  border-color: #dfe5e8;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-link-primary {
  background: #dfe5e8;
  color: #607d8b;
  border-color: #dfe5e8;
}
[data-pc-preset=preset-2] .material-icons-two-tone.text-primary {
  background-color: #607d8b;
}
[data-pc-preset=preset-2] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #607d8b;
  --bs-table-border-color: #708a97;
  --bs-table-striped-bg: #688491;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #708a97;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #63808d;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-secondary {
  background: #cceae7;
  color: #009688;
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-secondary > i {
  color: #009688;
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #009688;
  color: #fff;
}
[data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-2] .bg-light-secondary {
  background: #cceae7;
  color: #009688;
}
[data-pc-preset=preset-2] .link-secondary {
  color: #009688 !important;
}
[data-pc-preset=preset-2] .link-secondary:hover, [data-pc-preset=preset-2] .link-secondary:focus {
  color: #00786d !important;
}
[data-pc-preset=preset-2] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #009688;
  --bs-btn-border-color: #009688;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #008074;
  --bs-btn-hover-border-color: #00786d;
  --bs-btn-focus-shadow-rgb: 38, 166, 154;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #00786d;
  --bs-btn-active-border-color: #007166;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #009688;
  --bs-btn-disabled-border-color: #009688;
}
[data-pc-preset=preset-2] .btn-outline-secondary {
  --bs-btn-color: #009688;
  --bs-btn-border-color: #009688;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #009688;
  --bs-btn-hover-border-color: #009688;
  --bs-btn-focus-shadow-rgb: 0, 150, 136;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #009688;
  --bs-btn-active-border-color: #009688;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #009688;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #009688;
  --bs-gradient: none;
}
[data-pc-preset=preset-2] .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(96, 125, 139, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-2] .alert-secondary {
  --bs-alert-color: #005a52;
  --bs-alert-bg: #cceae7;
  --bs-alert-border-color: #b3e0db;
  --bs-alert-link-color: #004842;
}
[data-pc-preset=preset-2] .list-group-item-secondary {
  color: #009688;
  background-color: #cceae7;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-secondary:checked {
  border-color: #009688;
  background-color: #009688;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #cceae7;
  background-color: #cceae7;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23009688' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23009688'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 136, 0.25);
  border-color: #009688;
}
[data-pc-preset=preset-2] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23009688'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .btn-light-secondary {
  background: #cceae7;
  color: #009688;
  border-color: #cceae7;
}
[data-pc-preset=preset-2] .btn-light-secondary .material-icons-two-tone {
  background-color: #009688;
}
[data-pc-preset=preset-2] .btn-light-secondary:hover {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-2] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-2] .btn-light-secondary.focus, [data-pc-preset=preset-2] .btn-light-secondary:focus {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-2] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-2] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-2] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-light-secondary.dropdown-toggle {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-2] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-2] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-2] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-light-secondary {
  background: #009688;
  color: #fff;
  border-color: #009688;
}
[data-pc-preset=preset-2] .btn-link-secondary {
  background: transparent;
  color: #009688;
  border-color: transparent;
}
[data-pc-preset=preset-2] .btn-link-secondary .material-icons-two-tone {
  background-color: #009688;
}
[data-pc-preset=preset-2] .btn-link-secondary:hover {
  background: #cceae7;
  color: #009688;
  border-color: #cceae7;
}
[data-pc-preset=preset-2] .btn-link-secondary.focus, [data-pc-preset=preset-2] .btn-link-secondary:focus {
  background: #cceae7;
  color: #009688;
  border-color: #cceae7;
}
[data-pc-preset=preset-2] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-link-secondary.dropdown-toggle {
  background: #cceae7;
  color: #009688;
  border-color: #cceae7;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-link-secondary {
  background: #cceae7;
  color: #009688;
  border-color: #cceae7;
}
[data-pc-preset=preset-2] .material-icons-two-tone.text-secondary {
  background-color: #009688;
}
[data-pc-preset=preset-2] .table-secondary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #009688;
  --bs-table-border-color: #1aa194;
  --bs-table-striped-bg: #0d9b8e;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1aa194;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #05988a;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-3] {
  --pc-sidebar-active-color: #ec407a;
  --bs-blue: #203461;
  --bs-primary: #203461;
  --bs-primary-rgb: 32, 52, 97;
  --bs-primary-light: #e9ebef;
  --bs-secondary: #ec407a;
  --bs-secondary-rgb: 236, 64, 122;
  --bs-secondary-light: #fdecf2;
  --bs-link-color: #203461;
  --bs-link-color-rgb: 32, 52, 97;
  --bs-link-hover-color: #1a2a4e;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-secondary, $link-shade-percentage));
  --dt-row-selected: 32, 52, 97;
  --bs-body-bg: #eef2f6;
}
[data-pc-preset=preset-3] .bg-primary-dark {
  background: #18274f;
  color: #18274f;
}
[data-pc-preset=preset-3] .bg-secondary-dark {
  background: #e73267;
  color: #e73267;
}
[data-pc-preset=preset-3] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-3] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-3] .pc-sidebar .pc-item:hover > .pc-link {
  color: #ec407a;
}
[data-pc-preset=preset-3] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-3] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-3] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-3] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-3] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-3] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #ec407a;
}
[data-pc-preset=preset-3] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-3] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #ec407a;
}
[data-pc-preset=preset-3] .btn-link {
  --bs-btn-color: #203461;
  --bs-btn-hover-color: #1a2a4e;
  --bs-btn-active-color: #1a2a4e;
}
[data-pc-preset=preset-3] .accordion {
  --bs-accordion-btn-focus-border-color: #203461;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(32, 52, 97, 0.25);
  --bs-accordion-active-color: #203461;
  --bs-accordion-active-bg: #e9ebef;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23203461'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .list-group {
  --bs-list-group-active-bg: #203461;
  --bs-list-group-active-border-color: #203461;
}
[data-pc-preset=preset-3] .nav {
  --bs-nav-link-hover-color: #1a2a4e;
}
[data-pc-preset=preset-3] .nav-pills {
  --bs-nav-pills-link-active-bg: #203461;
}
[data-pc-preset=preset-3] .pagination {
  --bs-pagination-hover-color: #1a2a4e;
  --bs-pagination-focus-color: #1a2a4e;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(32, 52, 97, 0.25);
  --bs-pagination-active-bg: #203461;
  --bs-pagination-active-border-color: #203461;
}
[data-pc-preset=preset-3] .progress {
  --bs-progress-bar-bg: #203461;
}
[data-pc-preset=preset-3] .slider-selection {
  background-image: linear-gradient(to bottom, #4068c0 0, #4068c0 100%);
}
[data-pc-preset=preset-3] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #395dae 0, #395dae 100%);
}
[data-pc-preset=preset-3] .swal-button:not([disabled]):hover {
  background-color: #1a2a4e;
}
[data-pc-preset=preset-3] .swal-button:active {
  background-color: #1a2a4e;
}
[data-pc-preset=preset-3] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(32, 52, 97, 0.29);
}
[data-pc-preset=preset-3] .swal-content__input:focus {
  border-color: rgba(32, 52, 97, 0.29);
}
[data-pc-preset=preset-3] .swal-content__textarea:focus {
  border-color: rgba(32, 52, 97, 0.29);
}
[data-pc-preset=preset-3] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(32, 52, 97, 0.4) !important;
}
[data-pc-preset=preset-3] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #395dae 0, #395dae 100%);
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-primary {
  background: #d2d6df;
  color: #203461;
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-primary > i {
  color: #203461;
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-primary:hover {
  background: #203461;
  color: #fff;
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-3] .bg-light-primary {
  background: #d2d6df;
  color: #203461;
}
[data-pc-preset=preset-3] .link-primary {
  color: #203461 !important;
}
[data-pc-preset=preset-3] .link-primary:hover, [data-pc-preset=preset-3] .link-primary:focus {
  color: #1a2a4e !important;
}
[data-pc-preset=preset-3] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #203461;
  --bs-btn-border-color: #203461;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1b2c52;
  --bs-btn-hover-border-color: #1a2a4e;
  --bs-btn-focus-shadow-rgb: 65, 82, 121;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1a2a4e;
  --bs-btn-active-border-color: #182749;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #203461;
  --bs-btn-disabled-border-color: #203461;
}
[data-pc-preset=preset-3] .btn-outline-primary {
  --bs-btn-color: #203461;
  --bs-btn-border-color: #203461;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #203461;
  --bs-btn-hover-border-color: #203461;
  --bs-btn-focus-shadow-rgb: 32, 52, 97;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #203461;
  --bs-btn-active-border-color: #203461;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #203461;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #203461;
  --bs-gradient: none;
}
[data-pc-preset=preset-3] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(32, 52, 97, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-3] .alert-primary {
  --bs-alert-color: #131f3a;
  --bs-alert-bg: #d2d6df;
  --bs-alert-border-color: #bcc2d0;
  --bs-alert-link-color: #0f192e;
}
[data-pc-preset=preset-3] .list-group-item-primary {
  color: #203461;
  background-color: #d2d6df;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:checked {
  border-color: #203461;
  background-color: #203461;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: #d2d6df;
  background-color: #d2d6df;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23203461' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23203461'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(32, 52, 97, 0.25);
  border-color: #203461;
}
[data-pc-preset=preset-3] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23203461'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .btn-light-primary {
  background: #d2d6df;
  color: #203461;
  border-color: #d2d6df;
}
[data-pc-preset=preset-3] .btn-light-primary .material-icons-two-tone {
  background-color: #203461;
}
[data-pc-preset=preset-3] .btn-light-primary:hover {
  background: #203461;
  color: #fff;
  border-color: #203461;
}
[data-pc-preset=preset-3] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-3] .btn-light-primary.focus, [data-pc-preset=preset-3] .btn-light-primary:focus {
  background: #203461;
  color: #fff;
  border-color: #203461;
}
[data-pc-preset=preset-3] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-3] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle {
  background: #203461;
  color: #fff;
  border-color: #203461;
}
[data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-light-primary {
  background: #203461;
  color: #fff;
  border-color: #203461;
}
[data-pc-preset=preset-3] .btn-link-primary {
  background: transparent;
  color: #203461;
  border-color: transparent;
}
[data-pc-preset=preset-3] .btn-link-primary .material-icons-two-tone {
  background-color: #203461;
}
[data-pc-preset=preset-3] .btn-link-primary:hover {
  background: #d2d6df;
  color: #203461;
  border-color: #d2d6df;
}
[data-pc-preset=preset-3] .btn-link-primary.focus, [data-pc-preset=preset-3] .btn-link-primary:focus {
  background: #d2d6df;
  color: #203461;
  border-color: #d2d6df;
}
[data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-link-primary.dropdown-toggle {
  background: #d2d6df;
  color: #203461;
  border-color: #d2d6df;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-link-primary {
  background: #d2d6df;
  color: #203461;
  border-color: #d2d6df;
}
[data-pc-preset=preset-3] .material-icons-two-tone.text-primary {
  background-color: #203461;
}
[data-pc-preset=preset-3] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #203461;
  --bs-table-border-color: #364871;
  --bs-table-striped-bg: #2b3e69;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #364871;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #243864;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-secondary {
  background: #fbd9e4;
  color: #ec407a;
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-secondary > i {
  color: #ec407a;
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #ec407a;
  color: #fff;
}
[data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-3] .bg-light-secondary {
  background: #fbd9e4;
  color: #ec407a;
}
[data-pc-preset=preset-3] .link-secondary {
  color: #ec407a !important;
}
[data-pc-preset=preset-3] .link-secondary:hover, [data-pc-preset=preset-3] .link-secondary:focus {
  color: #bd3362 !important;
}
[data-pc-preset=preset-3] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ec407a;
  --bs-btn-border-color: #ec407a;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c93668;
  --bs-btn-hover-border-color: #bd3362;
  --bs-btn-focus-shadow-rgb: 239, 93, 142;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #bd3362;
  --bs-btn-active-border-color: #b1305c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ec407a;
  --bs-btn-disabled-border-color: #ec407a;
}
[data-pc-preset=preset-3] .btn-outline-secondary {
  --bs-btn-color: #ec407a;
  --bs-btn-border-color: #ec407a;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ec407a;
  --bs-btn-hover-border-color: #ec407a;
  --bs-btn-focus-shadow-rgb: 236, 64, 122;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ec407a;
  --bs-btn-active-border-color: #ec407a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ec407a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ec407a;
  --bs-gradient: none;
}
[data-pc-preset=preset-3] .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(32, 52, 97, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-3] .alert-secondary {
  --bs-alert-color: #8e2649;
  --bs-alert-bg: #fbd9e4;
  --bs-alert-border-color: #f9c6d7;
  --bs-alert-link-color: #721e3a;
}
[data-pc-preset=preset-3] .list-group-item-secondary {
  color: #ec407a;
  background-color: #fbd9e4;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-secondary:checked {
  border-color: #ec407a;
  background-color: #ec407a;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #fbd9e4;
  background-color: #fbd9e4;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ec407a' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ec407a'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(236, 64, 122, 0.25);
  border-color: #ec407a;
}
[data-pc-preset=preset-3] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ec407a'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .btn-light-secondary {
  background: #fbd9e4;
  color: #ec407a;
  border-color: #fbd9e4;
}
[data-pc-preset=preset-3] .btn-light-secondary .material-icons-two-tone {
  background-color: #ec407a;
}
[data-pc-preset=preset-3] .btn-light-secondary:hover {
  background: #ec407a;
  color: #fff;
  border-color: #ec407a;
}
[data-pc-preset=preset-3] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-3] .btn-light-secondary.focus, [data-pc-preset=preset-3] .btn-light-secondary:focus {
  background: #ec407a;
  color: #fff;
  border-color: #ec407a;
}
[data-pc-preset=preset-3] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-3] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-3] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-light-secondary.dropdown-toggle {
  background: #ec407a;
  color: #fff;
  border-color: #ec407a;
}
[data-pc-preset=preset-3] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-3] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-3] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-light-secondary {
  background: #ec407a;
  color: #fff;
  border-color: #ec407a;
}
[data-pc-preset=preset-3] .btn-link-secondary {
  background: transparent;
  color: #ec407a;
  border-color: transparent;
}
[data-pc-preset=preset-3] .btn-link-secondary .material-icons-two-tone {
  background-color: #ec407a;
}
[data-pc-preset=preset-3] .btn-link-secondary:hover {
  background: #fbd9e4;
  color: #ec407a;
  border-color: #fbd9e4;
}
[data-pc-preset=preset-3] .btn-link-secondary.focus, [data-pc-preset=preset-3] .btn-link-secondary:focus {
  background: #fbd9e4;
  color: #ec407a;
  border-color: #fbd9e4;
}
[data-pc-preset=preset-3] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-link-secondary.dropdown-toggle {
  background: #fbd9e4;
  color: #ec407a;
  border-color: #fbd9e4;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-link-secondary {
  background: #fbd9e4;
  color: #ec407a;
  border-color: #fbd9e4;
}
[data-pc-preset=preset-3] .material-icons-two-tone.text-secondary {
  background-color: #ec407a;
}
[data-pc-preset=preset-3] .table-secondary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #ec407a;
  --bs-table-border-color: #ee5387;
  --bs-table-striped-bg: #ed4a81;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #ee5387;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #ec447d;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-4] {
  --pc-sidebar-active-color: #c77e23;
  --bs-blue: #16595a;
  --bs-primary: #16595a;
  --bs-primary-rgb: 22, 89, 90;
  --bs-primary-light: #e8eeef;
  --bs-secondary: #c77e23;
  --bs-secondary-rgb: 199, 126, 35;
  --bs-secondary-light: #f9f2e9;
  --bs-link-color: #16595a;
  --bs-link-color-rgb: 22, 89, 90;
  --bs-link-hover-color: #124748;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-secondary, $link-shade-percentage));
  --dt-row-selected: 22, 89, 90;
  --bs-body-bg: #eef2f6;
}
[data-pc-preset=preset-4] .bg-primary-dark {
  background: #104848;
  color: #104848;
}
[data-pc-preset=preset-4] .bg-secondary-dark {
  background: #ba6b1a;
  color: #ba6b1a;
}
[data-pc-preset=preset-4] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-4] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-4] .pc-sidebar .pc-item:hover > .pc-link {
  color: #c77e23;
}
[data-pc-preset=preset-4] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-4] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-4] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-4] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-4] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-4] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #c77e23;
}
[data-pc-preset=preset-4] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-4] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #c77e23;
}
[data-pc-preset=preset-4] .btn-link {
  --bs-btn-color: #16595a;
  --bs-btn-hover-color: #124748;
  --bs-btn-active-color: #124748;
}
[data-pc-preset=preset-4] .accordion {
  --bs-accordion-btn-focus-border-color: #16595a;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(22, 89, 90, 0.25);
  --bs-accordion-active-color: #16595a;
  --bs-accordion-active-bg: #e8eeef;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2316595a'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .list-group {
  --bs-list-group-active-bg: #16595a;
  --bs-list-group-active-border-color: #16595a;
}
[data-pc-preset=preset-4] .nav {
  --bs-nav-link-hover-color: #124748;
}
[data-pc-preset=preset-4] .nav-pills {
  --bs-nav-pills-link-active-bg: #16595a;
}
[data-pc-preset=preset-4] .pagination {
  --bs-pagination-hover-color: #124748;
  --bs-pagination-focus-color: #124748;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(22, 89, 90, 0.25);
  --bs-pagination-active-bg: #16595a;
  --bs-pagination-active-border-color: #16595a;
}
[data-pc-preset=preset-4] .progress {
  --bs-progress-bar-bg: #16595a;
}
[data-pc-preset=preset-4] .slider-selection {
  background-image: linear-gradient(to bottom, #2fbec0 0, #2fbec0 100%);
}
[data-pc-preset=preset-4] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #2aaaac 0, #2aaaac 100%);
}
[data-pc-preset=preset-4] .swal-button:not([disabled]):hover {
  background-color: #114546;
}
[data-pc-preset=preset-4] .swal-button:active {
  background-color: #114546;
}
[data-pc-preset=preset-4] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(22, 89, 90, 0.29);
}
[data-pc-preset=preset-4] .swal-content__input:focus {
  border-color: rgba(22, 89, 90, 0.29);
}
[data-pc-preset=preset-4] .swal-content__textarea:focus {
  border-color: rgba(22, 89, 90, 0.29);
}
[data-pc-preset=preset-4] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(22, 89, 90, 0.4) !important;
}
[data-pc-preset=preset-4] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #2aaaac 0, #2aaaac 100%);
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-primary {
  background: #d0dede;
  color: #16595a;
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-primary > i {
  color: #16595a;
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-primary:hover {
  background: #16595a;
  color: #fff;
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-4] .bg-light-primary {
  background: #d0dede;
  color: #16595a;
}
[data-pc-preset=preset-4] .link-primary {
  color: #16595a !important;
}
[data-pc-preset=preset-4] .link-primary:hover, [data-pc-preset=preset-4] .link-primary:focus {
  color: #124748 !important;
}
[data-pc-preset=preset-4] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #16595a;
  --bs-btn-border-color: #16595a;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #134c4d;
  --bs-btn-hover-border-color: #124748;
  --bs-btn-focus-shadow-rgb: 57, 114, 115;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #124748;
  --bs-btn-active-border-color: #114344;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #16595a;
  --bs-btn-disabled-border-color: #16595a;
}
[data-pc-preset=preset-4] .btn-outline-primary {
  --bs-btn-color: #16595a;
  --bs-btn-border-color: #16595a;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #16595a;
  --bs-btn-hover-border-color: #16595a;
  --bs-btn-focus-shadow-rgb: 22, 89, 90;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #16595a;
  --bs-btn-active-border-color: #16595a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #16595a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #16595a;
  --bs-gradient: none;
}
[data-pc-preset=preset-4] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(22, 89, 90, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-4] .alert-primary {
  --bs-alert-color: #0d3536;
  --bs-alert-bg: #d0dede;
  --bs-alert-border-color: #b9cdce;
  --bs-alert-link-color: #0a2a2b;
}
[data-pc-preset=preset-4] .list-group-item-primary {
  color: #16595a;
  background-color: #d0dede;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:checked {
  border-color: #16595a;
  background-color: #16595a;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: #d0dede;
  background-color: #d0dede;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2316595a' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2316595a'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(22, 89, 90, 0.25);
  border-color: #16595a;
}
[data-pc-preset=preset-4] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2316595a'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .btn-light-primary {
  background: #d0dede;
  color: #16595a;
  border-color: #d0dede;
}
[data-pc-preset=preset-4] .btn-light-primary .material-icons-two-tone {
  background-color: #16595a;
}
[data-pc-preset=preset-4] .btn-light-primary:hover {
  background: #16595a;
  color: #fff;
  border-color: #16595a;
}
[data-pc-preset=preset-4] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-4] .btn-light-primary.focus, [data-pc-preset=preset-4] .btn-light-primary:focus {
  background: #16595a;
  color: #fff;
  border-color: #16595a;
}
[data-pc-preset=preset-4] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-4] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle {
  background: #16595a;
  color: #fff;
  border-color: #16595a;
}
[data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-light-primary {
  background: #16595a;
  color: #fff;
  border-color: #16595a;
}
[data-pc-preset=preset-4] .btn-link-primary {
  background: transparent;
  color: #16595a;
  border-color: transparent;
}
[data-pc-preset=preset-4] .btn-link-primary .material-icons-two-tone {
  background-color: #16595a;
}
[data-pc-preset=preset-4] .btn-link-primary:hover {
  background: #d0dede;
  color: #16595a;
  border-color: #d0dede;
}
[data-pc-preset=preset-4] .btn-link-primary.focus, [data-pc-preset=preset-4] .btn-link-primary:focus {
  background: #d0dede;
  color: #16595a;
  border-color: #d0dede;
}
[data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-link-primary.dropdown-toggle {
  background: #d0dede;
  color: #16595a;
  border-color: #d0dede;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-link-primary {
  background: #d0dede;
  color: #16595a;
  border-color: #d0dede;
}
[data-pc-preset=preset-4] .material-icons-two-tone.text-primary {
  background-color: #16595a;
}
[data-pc-preset=preset-4] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #16595a;
  --bs-table-border-color: #2d6a6b;
  --bs-table-striped-bg: #226162;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #2d6a6b;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #1b5c5d;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-secondary {
  background: #f4e5d3;
  color: #c77e23;
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-secondary > i {
  color: #c77e23;
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #c77e23;
  color: #fff;
}
[data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-4] .bg-light-secondary {
  background: #f4e5d3;
  color: #c77e23;
}
[data-pc-preset=preset-4] .link-secondary {
  color: #c77e23 !important;
}
[data-pc-preset=preset-4] .link-secondary:hover, [data-pc-preset=preset-4] .link-secondary:focus {
  color: #9f651c !important;
}
[data-pc-preset=preset-4] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #c77e23;
  --bs-btn-border-color: #c77e23;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #a96b1e;
  --bs-btn-hover-border-color: #9f651c;
  --bs-btn-focus-shadow-rgb: 207, 145, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #9f651c;
  --bs-btn-active-border-color: #955f1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #c77e23;
  --bs-btn-disabled-border-color: #c77e23;
}
[data-pc-preset=preset-4] .btn-outline-secondary {
  --bs-btn-color: #c77e23;
  --bs-btn-border-color: #c77e23;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c77e23;
  --bs-btn-hover-border-color: #c77e23;
  --bs-btn-focus-shadow-rgb: 199, 126, 35;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c77e23;
  --bs-btn-active-border-color: #c77e23;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c77e23;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c77e23;
  --bs-gradient: none;
}
[data-pc-preset=preset-4] .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(22, 89, 90, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-4] .alert-secondary {
  --bs-alert-color: #774c15;
  --bs-alert-bg: #f4e5d3;
  --bs-alert-border-color: #eed8bd;
  --bs-alert-link-color: #5f3d11;
}
[data-pc-preset=preset-4] .list-group-item-secondary {
  color: #c77e23;
  background-color: #f4e5d3;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-secondary:checked {
  border-color: #c77e23;
  background-color: #c77e23;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #f4e5d3;
  background-color: #f4e5d3;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23c77e23' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23c77e23'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(199, 126, 35, 0.25);
  border-color: #c77e23;
}
[data-pc-preset=preset-4] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c77e23'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .btn-light-secondary {
  background: #f4e5d3;
  color: #c77e23;
  border-color: #f4e5d3;
}
[data-pc-preset=preset-4] .btn-light-secondary .material-icons-two-tone {
  background-color: #c77e23;
}
[data-pc-preset=preset-4] .btn-light-secondary:hover {
  background: #c77e23;
  color: #fff;
  border-color: #c77e23;
}
[data-pc-preset=preset-4] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-4] .btn-light-secondary.focus, [data-pc-preset=preset-4] .btn-light-secondary:focus {
  background: #c77e23;
  color: #fff;
  border-color: #c77e23;
}
[data-pc-preset=preset-4] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-4] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-4] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-light-secondary.dropdown-toggle {
  background: #c77e23;
  color: #fff;
  border-color: #c77e23;
}
[data-pc-preset=preset-4] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-4] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-4] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-light-secondary {
  background: #c77e23;
  color: #fff;
  border-color: #c77e23;
}
[data-pc-preset=preset-4] .btn-link-secondary {
  background: transparent;
  color: #c77e23;
  border-color: transparent;
}
[data-pc-preset=preset-4] .btn-link-secondary .material-icons-two-tone {
  background-color: #c77e23;
}
[data-pc-preset=preset-4] .btn-link-secondary:hover {
  background: #f4e5d3;
  color: #c77e23;
  border-color: #f4e5d3;
}
[data-pc-preset=preset-4] .btn-link-secondary.focus, [data-pc-preset=preset-4] .btn-link-secondary:focus {
  background: #f4e5d3;
  color: #c77e23;
  border-color: #f4e5d3;
}
[data-pc-preset=preset-4] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-link-secondary.dropdown-toggle {
  background: #f4e5d3;
  color: #c77e23;
  border-color: #f4e5d3;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-link-secondary {
  background: #f4e5d3;
  color: #c77e23;
  border-color: #f4e5d3;
}
[data-pc-preset=preset-4] .material-icons-two-tone.text-secondary {
  background-color: #c77e23;
}
[data-pc-preset=preset-4] .table-secondary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #c77e23;
  --bs-table-border-color: #cd8b39;
  --bs-table-striped-bg: #ca842e;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #cd8b39;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #c88127;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-5] {
  --pc-sidebar-active-color: #3fb0ac;
  --bs-blue: #173e43;
  --bs-primary: #173e43;
  --bs-primary-rgb: 23, 62, 67;
  --bs-primary-light: #e8ecec;
  --bs-secondary: #3fb0ac;
  --bs-secondary-rgb: 63, 176, 172;
  --bs-secondary-light: #ecf7f7;
  --bs-link-color: #173e43;
  --bs-link-color-rgb: 23, 62, 67;
  --bs-link-hover-color: #123236;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-secondary, $link-shade-percentage));
  --dt-row-selected: 23, 62, 67;
  --bs-body-bg: #eef2f6;
}
[data-pc-preset=preset-5] .bg-primary-dark {
  background: #113034;
  color: #113034;
}
[data-pc-preset=preset-5] .bg-secondary-dark {
  background: #31a09b;
  color: #31a09b;
}
[data-pc-preset=preset-5] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-5] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-5] .pc-sidebar .pc-item:hover > .pc-link {
  color: #3fb0ac;
}
[data-pc-preset=preset-5] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-5] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-5] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-5] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-5] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-5] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #3fb0ac;
}
[data-pc-preset=preset-5] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-5] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-link {
  --bs-btn-color: #173e43;
  --bs-btn-hover-color: #123236;
  --bs-btn-active-color: #123236;
}
[data-pc-preset=preset-5] .accordion {
  --bs-accordion-btn-focus-border-color: #173e43;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(23, 62, 67, 0.25);
  --bs-accordion-active-color: #173e43;
  --bs-accordion-active-bg: #e8ecec;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23173e43'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .list-group {
  --bs-list-group-active-bg: #173e43;
  --bs-list-group-active-border-color: #173e43;
}
[data-pc-preset=preset-5] .nav {
  --bs-nav-link-hover-color: #123236;
}
[data-pc-preset=preset-5] .nav-pills {
  --bs-nav-pills-link-active-bg: #173e43;
}
[data-pc-preset=preset-5] .pagination {
  --bs-pagination-hover-color: #123236;
  --bs-pagination-focus-color: #123236;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(23, 62, 67, 0.25);
  --bs-pagination-active-bg: #173e43;
  --bs-pagination-active-border-color: #173e43;
}
[data-pc-preset=preset-5] .progress {
  --bs-progress-bar-bg: #173e43;
}
[data-pc-preset=preset-5] .slider-selection {
  background-image: linear-gradient(to bottom, #3896a2 0, #3896a2 100%);
}
[data-pc-preset=preset-5] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #31848f 0, #31848f 100%);
}
[data-pc-preset=preset-5] .swal-button:not([disabled]):hover {
  background-color: #102c30;
}
[data-pc-preset=preset-5] .swal-button:active {
  background-color: #102c30;
}
[data-pc-preset=preset-5] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(23, 62, 67, 0.29);
}
[data-pc-preset=preset-5] .swal-content__input:focus {
  border-color: rgba(23, 62, 67, 0.29);
}
[data-pc-preset=preset-5] .swal-content__textarea:focus {
  border-color: rgba(23, 62, 67, 0.29);
}
[data-pc-preset=preset-5] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(23, 62, 67, 0.4) !important;
}
[data-pc-preset=preset-5] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #31848f 0, #31848f 100%);
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-primary {
  background: #d1d8d9;
  color: #173e43;
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-primary > i {
  color: #173e43;
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-primary:hover {
  background: #173e43;
  color: #fff;
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-5] .bg-light-primary {
  background: #d1d8d9;
  color: #173e43;
}
[data-pc-preset=preset-5] .link-primary {
  color: #173e43 !important;
}
[data-pc-preset=preset-5] .link-primary:hover, [data-pc-preset=preset-5] .link-primary:focus {
  color: #123236 !important;
}
[data-pc-preset=preset-5] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #173e43;
  --bs-btn-border-color: #173e43;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #143539;
  --bs-btn-hover-border-color: #123236;
  --bs-btn-focus-shadow-rgb: 58, 91, 95;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #123236;
  --bs-btn-active-border-color: #112f32;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #173e43;
  --bs-btn-disabled-border-color: #173e43;
}
[data-pc-preset=preset-5] .btn-outline-primary {
  --bs-btn-color: #173e43;
  --bs-btn-border-color: #173e43;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #173e43;
  --bs-btn-hover-border-color: #173e43;
  --bs-btn-focus-shadow-rgb: 23, 62, 67;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #173e43;
  --bs-btn-active-border-color: #173e43;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #173e43;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #173e43;
  --bs-gradient: none;
}
[data-pc-preset=preset-5] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(23, 62, 67, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-5] .alert-primary {
  --bs-alert-color: #0e2528;
  --bs-alert-bg: #d1d8d9;
  --bs-alert-border-color: #b9c5c7;
  --bs-alert-link-color: #0b1e20;
}
[data-pc-preset=preset-5] .list-group-item-primary {
  color: #173e43;
  background-color: #d1d8d9;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:checked {
  border-color: #173e43;
  background-color: #173e43;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: #d1d8d9;
  background-color: #d1d8d9;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23173e43' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23173e43'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(23, 62, 67, 0.25);
  border-color: #173e43;
}
[data-pc-preset=preset-5] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23173e43'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .btn-light-primary {
  background: #d1d8d9;
  color: #173e43;
  border-color: #d1d8d9;
}
[data-pc-preset=preset-5] .btn-light-primary .material-icons-two-tone {
  background-color: #173e43;
}
[data-pc-preset=preset-5] .btn-light-primary:hover {
  background: #173e43;
  color: #fff;
  border-color: #173e43;
}
[data-pc-preset=preset-5] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-5] .btn-light-primary.focus, [data-pc-preset=preset-5] .btn-light-primary:focus {
  background: #173e43;
  color: #fff;
  border-color: #173e43;
}
[data-pc-preset=preset-5] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-5] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle {
  background: #173e43;
  color: #fff;
  border-color: #173e43;
}
[data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-light-primary {
  background: #173e43;
  color: #fff;
  border-color: #173e43;
}
[data-pc-preset=preset-5] .btn-link-primary {
  background: transparent;
  color: #173e43;
  border-color: transparent;
}
[data-pc-preset=preset-5] .btn-link-primary .material-icons-two-tone {
  background-color: #173e43;
}
[data-pc-preset=preset-5] .btn-link-primary:hover {
  background: #d1d8d9;
  color: #173e43;
  border-color: #d1d8d9;
}
[data-pc-preset=preset-5] .btn-link-primary.focus, [data-pc-preset=preset-5] .btn-link-primary:focus {
  background: #d1d8d9;
  color: #173e43;
  border-color: #d1d8d9;
}
[data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-link-primary.dropdown-toggle {
  background: #d1d8d9;
  color: #173e43;
  border-color: #d1d8d9;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-link-primary {
  background: #d1d8d9;
  color: #173e43;
  border-color: #d1d8d9;
}
[data-pc-preset=preset-5] .material-icons-two-tone.text-primary {
  background-color: #173e43;
}
[data-pc-preset=preset-5] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #173e43;
  --bs-table-border-color: #2e5156;
  --bs-table-striped-bg: #23484c;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #2e5156;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #1c4247;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-secondary {
  background: #d9efee;
  color: #3fb0ac;
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-secondary > i {
  color: #3fb0ac;
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #3fb0ac;
  color: #fff;
}
[data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-5] .bg-light-secondary {
  background: #d9efee;
  color: #3fb0ac;
}
[data-pc-preset=preset-5] .link-secondary {
  color: #3fb0ac !important;
}
[data-pc-preset=preset-5] .link-secondary:hover, [data-pc-preset=preset-5] .link-secondary:focus {
  color: #328d8a !important;
}
[data-pc-preset=preset-5] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3fb0ac;
  --bs-btn-border-color: #3fb0ac;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #369692;
  --bs-btn-hover-border-color: #328d8a;
  --bs-btn-focus-shadow-rgb: 92, 188, 184;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #328d8a;
  --bs-btn-active-border-color: #2f8481;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3fb0ac;
  --bs-btn-disabled-border-color: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-outline-secondary {
  --bs-btn-color: #3fb0ac;
  --bs-btn-border-color: #3fb0ac;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3fb0ac;
  --bs-btn-hover-border-color: #3fb0ac;
  --bs-btn-focus-shadow-rgb: 63, 176, 172;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3fb0ac;
  --bs-btn-active-border-color: #3fb0ac;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3fb0ac;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3fb0ac;
  --bs-gradient: none;
}
[data-pc-preset=preset-5] .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(23, 62, 67, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-5] .alert-secondary {
  --bs-alert-color: #266a67;
  --bs-alert-bg: #d9efee;
  --bs-alert-border-color: #c5e7e6;
  --bs-alert-link-color: #1e5552;
}
[data-pc-preset=preset-5] .list-group-item-secondary {
  color: #3fb0ac;
  background-color: #d9efee;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-secondary:checked {
  border-color: #3fb0ac;
  background-color: #3fb0ac;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #d9efee;
  background-color: #d9efee;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233fb0ac' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233fb0ac'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(63, 176, 172, 0.25);
  border-color: #3fb0ac;
}
[data-pc-preset=preset-5] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233fb0ac'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .btn-light-secondary {
  background: #d9efee;
  color: #3fb0ac;
  border-color: #d9efee;
}
[data-pc-preset=preset-5] .btn-light-secondary .material-icons-two-tone {
  background-color: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-light-secondary:hover {
  background: #3fb0ac;
  color: #fff;
  border-color: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-5] .btn-light-secondary.focus, [data-pc-preset=preset-5] .btn-light-secondary:focus {
  background: #3fb0ac;
  color: #fff;
  border-color: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-5] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-5] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-light-secondary.dropdown-toggle {
  background: #3fb0ac;
  color: #fff;
  border-color: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-5] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-5] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-light-secondary {
  background: #3fb0ac;
  color: #fff;
  border-color: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-link-secondary {
  background: transparent;
  color: #3fb0ac;
  border-color: transparent;
}
[data-pc-preset=preset-5] .btn-link-secondary .material-icons-two-tone {
  background-color: #3fb0ac;
}
[data-pc-preset=preset-5] .btn-link-secondary:hover {
  background: #d9efee;
  color: #3fb0ac;
  border-color: #d9efee;
}
[data-pc-preset=preset-5] .btn-link-secondary.focus, [data-pc-preset=preset-5] .btn-link-secondary:focus {
  background: #d9efee;
  color: #3fb0ac;
  border-color: #d9efee;
}
[data-pc-preset=preset-5] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-link-secondary.dropdown-toggle {
  background: #d9efee;
  color: #3fb0ac;
  border-color: #d9efee;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-link-secondary {
  background: #d9efee;
  color: #3fb0ac;
  border-color: #d9efee;
}
[data-pc-preset=preset-5] .material-icons-two-tone.text-secondary {
  background-color: #3fb0ac;
}
[data-pc-preset=preset-5] .table-secondary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3fb0ac;
  --bs-table-border-color: #52b8b4;
  --bs-table-striped-bg: #49b4b0;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #52b8b4;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #43b2ae;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-6] {
  --pc-sidebar-active-color: #2ca58d;
  --bs-blue: #0a2342;
  --bs-primary: #0a2342;
  --bs-primary-rgb: 10, 35, 66;
  --bs-primary-light: #e7e9ec;
  --bs-secondary: #2ca58d;
  --bs-secondary-rgb: 44, 165, 141;
  --bs-secondary-light: #eaf6f4;
  --bs-link-color: #0a2342;
  --bs-link-color-rgb: 10, 35, 66;
  --bs-link-hover-color: #081c35;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-secondary, $link-shade-percentage));
  --dt-row-selected: 10, 35, 66;
  --bs-body-bg: #eef2f6;
}
[data-pc-preset=preset-6] .bg-primary-dark {
  background: #071a33;
  color: #071a33;
}
[data-pc-preset=preset-6] .bg-secondary-dark {
  background: #21937a;
  color: #21937a;
}
[data-pc-preset=preset-6] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-6] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-6] .pc-sidebar .pc-item:hover > .pc-link {
  color: #2ca58d;
}
[data-pc-preset=preset-6] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-6] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-6] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-6] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-6] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-6] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #2ca58d;
}
[data-pc-preset=preset-6] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-6] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #2ca58d;
}
[data-pc-preset=preset-6] .btn-link {
  --bs-btn-color: #0a2342;
  --bs-btn-hover-color: #081c35;
  --bs-btn-active-color: #081c35;
}
[data-pc-preset=preset-6] .accordion {
  --bs-accordion-btn-focus-border-color: #0a2342;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(10, 35, 66, 0.25);
  --bs-accordion-active-color: #0a2342;
  --bs-accordion-active-bg: #e7e9ec;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a2342'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .list-group {
  --bs-list-group-active-bg: #0a2342;
  --bs-list-group-active-border-color: #0a2342;
}
[data-pc-preset=preset-6] .nav {
  --bs-nav-link-hover-color: #081c35;
}
[data-pc-preset=preset-6] .nav-pills {
  --bs-nav-pills-link-active-bg: #0a2342;
}
[data-pc-preset=preset-6] .pagination {
  --bs-pagination-hover-color: #081c35;
  --bs-pagination-focus-color: #081c35;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(10, 35, 66, 0.25);
  --bs-pagination-active-bg: #0a2342;
  --bs-pagination-active-border-color: #0a2342;
}
[data-pc-preset=preset-6] .progress {
  --bs-progress-bar-bg: #0a2342;
}
[data-pc-preset=preset-6] .slider-selection {
  background-image: linear-gradient(to bottom, #1b5eb1 0, #1b5eb1 100%);
}
[data-pc-preset=preset-6] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #17529b 0, #17529b 100%);
}
[data-pc-preset=preset-6] .swal-button:not([disabled]):hover {
  background-color: #07172c;
}
[data-pc-preset=preset-6] .swal-button:active {
  background-color: #07172c;
}
[data-pc-preset=preset-6] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(10, 35, 66, 0.29);
}
[data-pc-preset=preset-6] .swal-content__input:focus {
  border-color: rgba(10, 35, 66, 0.29);
}
[data-pc-preset=preset-6] .swal-content__textarea:focus {
  border-color: rgba(10, 35, 66, 0.29);
}
[data-pc-preset=preset-6] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(10, 35, 66, 0.4) !important;
}
[data-pc-preset=preset-6] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #17529b 0, #17529b 100%);
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-primary {
  background: #ced3d9;
  color: #0a2342;
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-primary > i {
  color: #0a2342;
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-primary:hover {
  background: #0a2342;
  color: #fff;
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-6] .bg-light-primary {
  background: #ced3d9;
  color: #0a2342;
}
[data-pc-preset=preset-6] .link-primary {
  color: #0a2342 !important;
}
[data-pc-preset=preset-6] .link-primary:hover, [data-pc-preset=preset-6] .link-primary:focus {
  color: #081c35 !important;
}
[data-pc-preset=preset-6] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0a2342;
  --bs-btn-border-color: #0a2342;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #091e38;
  --bs-btn-hover-border-color: #081c35;
  --bs-btn-focus-shadow-rgb: 47, 68, 94;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #081c35;
  --bs-btn-active-border-color: #081a32;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0a2342;
  --bs-btn-disabled-border-color: #0a2342;
}
[data-pc-preset=preset-6] .btn-outline-primary {
  --bs-btn-color: #0a2342;
  --bs-btn-border-color: #0a2342;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0a2342;
  --bs-btn-hover-border-color: #0a2342;
  --bs-btn-focus-shadow-rgb: 10, 35, 66;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0a2342;
  --bs-btn-active-border-color: #0a2342;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0a2342;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0a2342;
  --bs-gradient: none;
}
[data-pc-preset=preset-6] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(10, 35, 66, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-6] .alert-primary {
  --bs-alert-color: #061528;
  --bs-alert-bg: #ced3d9;
  --bs-alert-border-color: #b6bdc6;
  --bs-alert-link-color: #051120;
}
[data-pc-preset=preset-6] .list-group-item-primary {
  color: #0a2342;
  background-color: #ced3d9;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:checked {
  border-color: #0a2342;
  background-color: #0a2342;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: #ced3d9;
  background-color: #ced3d9;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%230a2342' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%230a2342'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(10, 35, 66, 0.25);
  border-color: #0a2342;
}
[data-pc-preset=preset-6] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230a2342'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .btn-light-primary {
  background: #ced3d9;
  color: #0a2342;
  border-color: #ced3d9;
}
[data-pc-preset=preset-6] .btn-light-primary .material-icons-two-tone {
  background-color: #0a2342;
}
[data-pc-preset=preset-6] .btn-light-primary:hover {
  background: #0a2342;
  color: #fff;
  border-color: #0a2342;
}
[data-pc-preset=preset-6] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-6] .btn-light-primary.focus, [data-pc-preset=preset-6] .btn-light-primary:focus {
  background: #0a2342;
  color: #fff;
  border-color: #0a2342;
}
[data-pc-preset=preset-6] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-6] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle {
  background: #0a2342;
  color: #fff;
  border-color: #0a2342;
}
[data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-light-primary {
  background: #0a2342;
  color: #fff;
  border-color: #0a2342;
}
[data-pc-preset=preset-6] .btn-link-primary {
  background: transparent;
  color: #0a2342;
  border-color: transparent;
}
[data-pc-preset=preset-6] .btn-link-primary .material-icons-two-tone {
  background-color: #0a2342;
}
[data-pc-preset=preset-6] .btn-link-primary:hover {
  background: #ced3d9;
  color: #0a2342;
  border-color: #ced3d9;
}
[data-pc-preset=preset-6] .btn-link-primary.focus, [data-pc-preset=preset-6] .btn-link-primary:focus {
  background: #ced3d9;
  color: #0a2342;
  border-color: #ced3d9;
}
[data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-link-primary.dropdown-toggle {
  background: #ced3d9;
  color: #0a2342;
  border-color: #ced3d9;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-link-primary {
  background: #ced3d9;
  color: #0a2342;
  border-color: #ced3d9;
}
[data-pc-preset=preset-6] .material-icons-two-tone.text-primary {
  background-color: #0a2342;
}
[data-pc-preset=preset-6] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #0a2342;
  --bs-table-border-color: #233955;
  --bs-table-striped-bg: #162e4b;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #233955;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #0f2746;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-secondary {
  background: #d5ede8;
  color: #2ca58d;
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-secondary > i {
  color: #2ca58d;
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #2ca58d;
  color: #fff;
}
[data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-6] .bg-light-secondary {
  background: #d5ede8;
  color: #2ca58d;
}
[data-pc-preset=preset-6] .link-secondary {
  color: #2ca58d !important;
}
[data-pc-preset=preset-6] .link-secondary:hover, [data-pc-preset=preset-6] .link-secondary:focus {
  color: #238471 !important;
}
[data-pc-preset=preset-6] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2ca58d;
  --bs-btn-border-color: #2ca58d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #258c78;
  --bs-btn-hover-border-color: #238471;
  --bs-btn-focus-shadow-rgb: 76, 179, 158;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #238471;
  --bs-btn-active-border-color: #217c6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2ca58d;
  --bs-btn-disabled-border-color: #2ca58d;
}
[data-pc-preset=preset-6] .btn-outline-secondary {
  --bs-btn-color: #2ca58d;
  --bs-btn-border-color: #2ca58d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2ca58d;
  --bs-btn-hover-border-color: #2ca58d;
  --bs-btn-focus-shadow-rgb: 44, 165, 141;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2ca58d;
  --bs-btn-active-border-color: #2ca58d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2ca58d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2ca58d;
  --bs-gradient: none;
}
[data-pc-preset=preset-6] .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(10, 35, 66, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-6] .alert-secondary {
  --bs-alert-color: #1a6355;
  --bs-alert-bg: #d5ede8;
  --bs-alert-border-color: #c0e4dd;
  --bs-alert-link-color: #154f44;
}
[data-pc-preset=preset-6] .list-group-item-secondary {
  color: #2ca58d;
  background-color: #d5ede8;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-secondary:checked {
  border-color: #2ca58d;
  background-color: #2ca58d;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #d5ede8;
  background-color: #d5ede8;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232ca58d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232ca58d'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(44, 165, 141, 0.25);
  border-color: #2ca58d;
}
[data-pc-preset=preset-6] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232ca58d'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .btn-light-secondary {
  background: #d5ede8;
  color: #2ca58d;
  border-color: #d5ede8;
}
[data-pc-preset=preset-6] .btn-light-secondary .material-icons-two-tone {
  background-color: #2ca58d;
}
[data-pc-preset=preset-6] .btn-light-secondary:hover {
  background: #2ca58d;
  color: #fff;
  border-color: #2ca58d;
}
[data-pc-preset=preset-6] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-6] .btn-light-secondary.focus, [data-pc-preset=preset-6] .btn-light-secondary:focus {
  background: #2ca58d;
  color: #fff;
  border-color: #2ca58d;
}
[data-pc-preset=preset-6] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-6] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-6] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-light-secondary.dropdown-toggle {
  background: #2ca58d;
  color: #fff;
  border-color: #2ca58d;
}
[data-pc-preset=preset-6] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-6] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-6] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-light-secondary {
  background: #2ca58d;
  color: #fff;
  border-color: #2ca58d;
}
[data-pc-preset=preset-6] .btn-link-secondary {
  background: transparent;
  color: #2ca58d;
  border-color: transparent;
}
[data-pc-preset=preset-6] .btn-link-secondary .material-icons-two-tone {
  background-color: #2ca58d;
}
[data-pc-preset=preset-6] .btn-link-secondary:hover {
  background: #d5ede8;
  color: #2ca58d;
  border-color: #d5ede8;
}
[data-pc-preset=preset-6] .btn-link-secondary.focus, [data-pc-preset=preset-6] .btn-link-secondary:focus {
  background: #d5ede8;
  color: #2ca58d;
  border-color: #d5ede8;
}
[data-pc-preset=preset-6] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-link-secondary.dropdown-toggle {
  background: #d5ede8;
  color: #2ca58d;
  border-color: #d5ede8;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-link-secondary {
  background: #d5ede8;
  color: #2ca58d;
  border-color: #d5ede8;
}
[data-pc-preset=preset-6] .material-icons-two-tone.text-secondary {
  background-color: #2ca58d;
}
[data-pc-preset=preset-6] .table-secondary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #2ca58d;
  --bs-table-border-color: #41ae98;
  --bs-table-striped-bg: #37aa93;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #41ae98;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #30a78f;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-7] {
  --pc-sidebar-active-color: #3f51b5;
  --bs-blue: #3f51b5;
  --bs-primary: #3f51b5;
  --bs-primary-rgb: 63, 81, 181;
  --bs-primary-light: #eceef8;
  --bs-secondary: #3f51b5;
  --bs-secondary-rgb: 63, 81, 181;
  --bs-secondary-light: #eceef8;
  --bs-link-color: #3f51b5;
  --bs-link-color-rgb: 63, 81, 181;
  --bs-link-hover-color: #324191;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-secondary, $link-shade-percentage));
  --dt-row-selected: 63, 81, 181;
  --bs-body-bg: #eef2f6;
}
[data-pc-preset=preset-7] .bg-primary-dark {
  background: #3140a5;
  color: #3140a5;
}
[data-pc-preset=preset-7] .bg-secondary-dark {
  background: #3140a5;
  color: #3140a5;
}
[data-pc-preset=preset-7] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-7] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-7] .pc-sidebar .pc-item:hover > .pc-link {
  color: #3f51b5;
}
[data-pc-preset=preset-7] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-7] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-7] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-7] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-7] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-7] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #3f51b5;
}
[data-pc-preset=preset-7] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-7] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #3f51b5;
}
[data-pc-preset=preset-7] .btn-link {
  --bs-btn-color: #3f51b5;
  --bs-btn-hover-color: #324191;
  --bs-btn-active-color: #324191;
}
[data-pc-preset=preset-7] .accordion {
  --bs-accordion-btn-focus-border-color: #3f51b5;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.25);
  --bs-accordion-active-color: #3f51b5;
  --bs-accordion-active-bg: #eceef8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233f51b5'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .list-group {
  --bs-list-group-active-bg: #3f51b5;
  --bs-list-group-active-border-color: #3f51b5;
}
[data-pc-preset=preset-7] .nav {
  --bs-nav-link-hover-color: #324191;
}
[data-pc-preset=preset-7] .nav-pills {
  --bs-nav-pills-link-active-bg: #3f51b5;
}
[data-pc-preset=preset-7] .pagination {
  --bs-pagination-hover-color: #324191;
  --bs-pagination-focus-color: #324191;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.25);
  --bs-pagination-active-bg: #3f51b5;
  --bs-pagination-active-border-color: #3f51b5;
}
[data-pc-preset=preset-7] .progress {
  --bs-progress-bar-bg: #3f51b5;
}
[data-pc-preset=preset-7] .slider-selection {
  background-image: linear-gradient(to bottom, #98a2db 0, #98a2db 100%);
}
[data-pc-preset=preset-7] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #8591d5 0, #8591d5 100%);
}
[data-pc-preset=preset-7] .swal-button:not([disabled]):hover {
  background-color: #3849a2;
}
[data-pc-preset=preset-7] .swal-button:active {
  background-color: #3849a2;
}
[data-pc-preset=preset-7] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(63, 81, 181, 0.29);
}
[data-pc-preset=preset-7] .swal-content__input:focus {
  border-color: rgba(63, 81, 181, 0.29);
}
[data-pc-preset=preset-7] .swal-content__textarea:focus {
  border-color: rgba(63, 81, 181, 0.29);
}
[data-pc-preset=preset-7] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(63, 81, 181, 0.4) !important;
}
[data-pc-preset=preset-7] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #8591d5 0, #8591d5 100%);
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-primary {
  background: #d9dcf0;
  color: #3f51b5;
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-primary > i {
  color: #3f51b5;
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-primary:hover {
  background: #3f51b5;
  color: #fff;
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-7] .bg-light-primary {
  background: #d9dcf0;
  color: #3f51b5;
}
[data-pc-preset=preset-7] .link-primary {
  color: #3f51b5 !important;
}
[data-pc-preset=preset-7] .link-primary:hover, [data-pc-preset=preset-7] .link-primary:focus {
  color: #324191 !important;
}
[data-pc-preset=preset-7] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3f51b5;
  --bs-btn-border-color: #3f51b5;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #36459a;
  --bs-btn-hover-border-color: #324191;
  --bs-btn-focus-shadow-rgb: 92, 107, 192;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #324191;
  --bs-btn-active-border-color: #2f3d88;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3f51b5;
  --bs-btn-disabled-border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-outline-primary {
  --bs-btn-color: #3f51b5;
  --bs-btn-border-color: #3f51b5;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3f51b5;
  --bs-btn-hover-border-color: #3f51b5;
  --bs-btn-focus-shadow-rgb: 63, 81, 181;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3f51b5;
  --bs-btn-active-border-color: #3f51b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3f51b5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3f51b5;
  --bs-gradient: none;
}
[data-pc-preset=preset-7] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(63, 81, 181, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-7] .alert-primary {
  --bs-alert-color: #26316d;
  --bs-alert-bg: #d9dcf0;
  --bs-alert-border-color: #c5cbe9;
  --bs-alert-link-color: #1e2757;
}
[data-pc-preset=preset-7] .list-group-item-primary {
  color: #3f51b5;
  background-color: #d9dcf0;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:checked {
  border-color: #3f51b5;
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: #d9dcf0;
  background-color: #d9dcf0;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233f51b5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233f51b5'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.25);
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233f51b5'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .btn-light-primary {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-light-primary .material-icons-two-tone {
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-primary:hover {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-7] .btn-light-primary.focus, [data-pc-preset=preset-7] .btn-light-primary:focus {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-7] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-light-primary {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-link-primary {
  background: transparent;
  color: #3f51b5;
  border-color: transparent;
}
[data-pc-preset=preset-7] .btn-link-primary .material-icons-two-tone {
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-link-primary:hover {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-link-primary.focus, [data-pc-preset=preset-7] .btn-link-primary:focus {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-link-primary.dropdown-toggle {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-link-primary {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .material-icons-two-tone.text-primary {
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3f51b5;
  --bs-table-border-color: #5262bc;
  --bs-table-striped-bg: #495ab9;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #5262bc;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #4354b6;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-secondary {
  background: #d9dcf0;
  color: #3f51b5;
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-secondary > i {
  color: #3f51b5;
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #3f51b5;
  color: #fff;
}
[data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-7] .bg-light-secondary {
  background: #d9dcf0;
  color: #3f51b5;
}
[data-pc-preset=preset-7] .link-secondary {
  color: #3f51b5 !important;
}
[data-pc-preset=preset-7] .link-secondary:hover, [data-pc-preset=preset-7] .link-secondary:focus {
  color: #324191 !important;
}
[data-pc-preset=preset-7] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3f51b5;
  --bs-btn-border-color: #3f51b5;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #36459a;
  --bs-btn-hover-border-color: #324191;
  --bs-btn-focus-shadow-rgb: 92, 107, 192;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #324191;
  --bs-btn-active-border-color: #2f3d88;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3f51b5;
  --bs-btn-disabled-border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-outline-secondary {
  --bs-btn-color: #3f51b5;
  --bs-btn-border-color: #3f51b5;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3f51b5;
  --bs-btn-hover-border-color: #3f51b5;
  --bs-btn-focus-shadow-rgb: 63, 81, 181;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3f51b5;
  --bs-btn-active-border-color: #3f51b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3f51b5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3f51b5;
  --bs-gradient: none;
}
[data-pc-preset=preset-7] .text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(63, 81, 181, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-7] .alert-secondary {
  --bs-alert-color: #26316d;
  --bs-alert-bg: #d9dcf0;
  --bs-alert-border-color: #c5cbe9;
  --bs-alert-link-color: #1e2757;
}
[data-pc-preset=preset-7] .list-group-item-secondary {
  color: #3f51b5;
  background-color: #d9dcf0;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-secondary:checked {
  border-color: #3f51b5;
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #d9dcf0;
  background-color: #d9dcf0;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233f51b5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233f51b5'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.25);
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233f51b5'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .btn-light-secondary {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-light-secondary .material-icons-two-tone {
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-secondary:hover {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-7] .btn-light-secondary.focus, [data-pc-preset=preset-7] .btn-light-secondary:focus {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-7] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-7] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-light-secondary.dropdown-toggle {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-7] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-7] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-light-secondary {
  background: #3f51b5;
  color: #fff;
  border-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-link-secondary {
  background: transparent;
  color: #3f51b5;
  border-color: transparent;
}
[data-pc-preset=preset-7] .btn-link-secondary .material-icons-two-tone {
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .btn-link-secondary:hover {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-link-secondary.focus, [data-pc-preset=preset-7] .btn-link-secondary:focus {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-link-secondary.dropdown-toggle {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-link-secondary {
  background: #d9dcf0;
  color: #3f51b5;
  border-color: #d9dcf0;
}
[data-pc-preset=preset-7] .material-icons-two-tone.text-secondary {
  background-color: #3f51b5;
}
[data-pc-preset=preset-7] .table-secondary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3f51b5;
  --bs-table-border-color: #5262bc;
  --bs-table-striped-bg: #495ab9;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #5262bc;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #4354b6;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-direction=rtl] {
  direction: rtl;
  text-align: right;
}
[data-pc-direction=rtl] .p-l-0 {
  padding-right: 0px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-l-0[class*=col] {
  padding-left: 15px;
}
[data-pc-direction=rtl] .p-r-0 {
  padding-left: 0px;
  padding-right: 0;
}
[data-pc-direction=rtl] .p-r-0[class*=col] {
  padding-right: 15px;
}
[data-pc-direction=rtl] .m-l-0 {
  margin-right: 0px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-0 {
  margin-left: 0px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-5 {
  padding-right: 5px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-5 {
  padding-left: 5px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-5 {
  margin-right: 5px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-5 {
  margin-left: 5px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-10 {
  padding-right: 10px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-10 {
  padding-left: 10px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-10 {
  margin-right: 10px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-10 {
  margin-left: 10px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-15 {
  padding-right: 15px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-15 {
  padding-left: 15px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-15 {
  margin-right: 15px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-15 {
  margin-left: 15px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-20 {
  padding-right: 20px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-20 {
  padding-left: 20px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-20 {
  margin-right: 20px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-20 {
  margin-left: 20px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-25 {
  padding-right: 25px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-25 {
  padding-left: 25px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-25 {
  margin-right: 25px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-25 {
  margin-left: 25px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-30 {
  padding-right: 30px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-30 {
  padding-left: 30px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-30 {
  margin-right: 30px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-30 {
  margin-left: 30px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-35 {
  padding-right: 35px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-35 {
  padding-left: 35px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-35 {
  margin-right: 35px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-35 {
  margin-left: 35px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-40 {
  padding-right: 40px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-40 {
  padding-left: 40px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-40 {
  margin-right: 40px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-40 {
  margin-left: 40px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-45 {
  padding-right: 45px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-45 {
  padding-left: 45px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-45 {
  margin-right: 45px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-45 {
  margin-left: 45px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-50 {
  padding-right: 50px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-50 {
  padding-left: 50px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-50 {
  margin-right: 50px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-50 {
  margin-left: 50px;
  margin-right: 0;
}
[data-pc-direction=rtl] ol:not([class]),
[data-pc-direction=rtl] ul:not([class]) {
  padding-left: 0;
  padding-right: 2rem;
}
[data-pc-direction=rtl] dl,
[data-pc-direction=rtl] .list-inline,
[data-pc-direction=rtl] .list-group,
[data-pc-direction=rtl] .list-unstyled {
  padding-right: 0;
  padding-left: 0;
}
[data-pc-direction=rtl] dd {
  margin-right: 0;
}
[data-pc-direction=rtl] .ph-duotone {
  direction: ltr;
}
[data-pc-direction=rtl] .breadcrumb-item + .breadcrumb-item::before {
  float: right;
}
[data-pc-direction=rtl] .dropdown-menu {
  text-align: right;
}
[data-pc-direction=rtl] .dropdown .dropdown-item i {
  margin-right: 0;
  margin-left: 10px;
}
[data-pc-direction=rtl] .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 3.75rem;
}
[data-pc-direction=rtl] .alert-dismissible .btn-close {
  right: auto;
  left: 0;
}
[data-pc-direction=rtl] .accordion-button::after {
  margin-right: auto;
  margin-left: 0;
}
[data-pc-direction=rtl] .pc-container {
  margin-right: 260px;
  margin-left: 20px;
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-container {
    margin-right: 20px;
  }
}
[data-pc-direction=rtl] .pct-c-btn {
  right: auto;
  left: 10px;
  border-radius: 50% 50% 50% 4px;
}
[data-pc-direction=rtl] .pc-sidebar ul {
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-sidebar .pc-arrow {
  float: left;
}
[data-pc-direction=rtl] .pc-sidebar .pc-micon {
  margin-right: 0;
  margin-left: 15px;
}
@media (min-width: 1025px) {
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide {
    transform: translateX(260px);
  }
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-container {
    margin-right: 20px;
    margin-left: 20px;
  }
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-header {
    right: 0;
  }
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-sidebar {
    left: auto;
    right: -260px;
  }
  [data-pc-direction=rtl] .pc-sidebar.mob-sidebar-active {
    right: 0;
  }
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu:after {
  left: auto;
  right: 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-link {
  padding: 12px 60px 12px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-link:after {
  left: auto;
  right: 45px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu:after {
  left: auto;
  right: 46px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link {
  padding: 12px 80px 12px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link:after {
  left: auto;
  right: 62px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu:after {
  left: auto;
  right: 63px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 12px 95px 12px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link:after {
  left: auto;
  right: 79px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-navbar > li > .pc-submenu::before {
  right: 40px;
  left: auto;
}
[data-pc-direction=rtl] .pc-header {
  left: 0;
  right: 260px;
}
[data-pc-direction=rtl] .pc-header ul {
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-header .m-header {
  padding: 16px 24px 16px 10px;
}
[data-pc-direction=rtl] .pc-header .user-avtar {
  margin-right: 0;
  margin-left: 10px;
}
[data-pc-direction=rtl] .pc-header .header-search .icon-search {
  left: auto;
  right: 15px;
}
[data-pc-direction=rtl] .pc-header .header-search .btn-search {
  right: auto;
  left: 9px;
}
[data-pc-direction=rtl] .pc-header .pc-h-dropdown.dropdown-menu-end {
  right: auto !important;
  left: 0 !important;
}
[data-pc-direction=rtl] .pc-header .pc-mega-list {
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-header .pc-mega-menu .pc-mega-dmenu {
  left: 40px !important;
  right: 20px !important;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .upgradeplan-block::before {
  right: auto;
  left: -70px;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .upgradeplan-block::after {
  right: auto;
  left: -150px;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .settings-block .form-switch {
  padding-left: 2.5em;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .settings-block .form-switch .form-check-input {
  float: left;
}
@media (max-width: 575.98px) {
  [data-pc-direction=rtl] .pc-header .header-user-profile .pc-head-link .user-avtar {
    margin-left: 0;
  }
  [data-pc-direction=rtl] .pc-header .pc-h-item .pc-h-dropdown {
    right: 0 !important;
  }
  [data-pc-direction=rtl] .pc-header .pc-mega-menu .pc-mega-dmenu {
    left: 0 !important;
  }
}
[data-pc-direction=rtl] .page-header h5,
[data-pc-direction=rtl] .page-header .h5 {
  border-left: 1px solid #cfd6db;
  border-right: none;
  margin-right: 0;
  padding-right: 0;
  margin-left: 8px;
  padding-left: 8px;
}
[data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple]):not([size]),
[data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple])[size="1"],
[data-pc-direction=rtl] .form-select.is-valid:not([multiple]):not([size]),
[data-pc-direction=rtl] .form-select.is-valid:not([multiple])[size="1"],
[data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple]):not([size]),
[data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple])[size="1"],
[data-pc-direction=rtl] .form-select.is-invalid:not([multiple]):not([size]),
[data-pc-direction=rtl] .form-select.is-invalid:not([multiple])[size="1"] {
  background-position: left 0.75rem center, center left 2.75rem;
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .was-validated textarea.form-control:invalid,
[data-pc-direction=rtl] .was-validated textarea.custom-select:invalid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-selector:invalid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-input:invalid,
[data-pc-direction=rtl] textarea.form-control.is-invalid,
[data-pc-direction=rtl] textarea.is-invalid.custom-select,
[data-pc-direction=rtl] textarea.is-invalid.dataTable-selector,
[data-pc-direction=rtl] textarea.is-invalid.dataTable-input,
[data-pc-direction=rtl] .was-validated textarea.form-control:valid,
[data-pc-direction=rtl] .was-validated textarea.custom-select:valid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-selector:valid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-input:valid,
[data-pc-direction=rtl] textarea.form-control.is-valid,
[data-pc-direction=rtl] textarea.is-valid.custom-select,
[data-pc-direction=rtl] textarea.is-valid.dataTable-selector,
[data-pc-direction=rtl] textarea.is-valid.dataTable-input {
  background-position: top calc(0.375em + 0.4rem) left calc(0.375em + 0.4rem);
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .was-validated .form-control:invalid,
[data-pc-direction=rtl] .was-validated .custom-select:invalid,
[data-pc-direction=rtl] .was-validated .dataTable-selector:invalid,
[data-pc-direction=rtl] .was-validated .dataTable-input:invalid,
[data-pc-direction=rtl] .form-control.is-invalid,
[data-pc-direction=rtl] .is-invalid.custom-select,
[data-pc-direction=rtl] .is-invalid.dataTable-selector,
[data-pc-direction=rtl] .is-invalid.dataTable-input,
[data-pc-direction=rtl] .was-validated .form-control:valid,
[data-pc-direction=rtl] .was-validated .custom-select:valid,
[data-pc-direction=rtl] .was-validated .dataTable-selector:valid,
[data-pc-direction=rtl] .was-validated .dataTable-input:valid,
[data-pc-direction=rtl] .form-control.is-valid,
[data-pc-direction=rtl] .is-valid.custom-select,
[data-pc-direction=rtl] .is-valid.dataTable-selector,
[data-pc-direction=rtl] .is-valid.dataTable-input {
  background-position: left calc(0.375em + 0.4rem) center;
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .cropper {
  direction: ltr;
}
[data-pc-direction=rtl] .pc-footer {
  margin-left: 20px;
  margin-right: 260px;
}
@media (min-width: 576px) {
  [data-pc-direction=rtl] .pc-footer .footer-wrapper .justify-content-sm-end {
    justify-content: flex-end !important;
  }
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-footer {
    margin-right: 20px;
  }
}
[data-pc-direction=rtl] .dashnum-card .round.small {
  right: auto;
  left: -15px;
}
[data-pc-direction=rtl] .dashnum-card .round.big {
  right: auto;
  left: -95px;
}
[data-pc-direction=rtl] .dashnum-card.dashnum-card-small .round.small {
  right: auto;
  left: -130px;
}
[data-pc-direction=rtl] .dashnum-card.dashnum-card-small .round.big {
  right: auto;
  left: -180px;
}
[data-pc-direction=rtl] .order-card .card-icon {
  right: auto;
  left: 14px;
}
[data-pc-direction=rtl] .social-widget-card i {
  right: auto;
  left: 14px;
}
[data-pc-direction=rtl] .user-activity-card .u-img .profile-img {
  right: auto;
  left: -10px;
}
[data-pc-direction=rtl] .latest-update-card .card-body .latest-update-box:after {
  right: 82px;
  left: auto;
}
[data-pc-direction=rtl] .task-card li {
  padding-left: 0;
  padding-right: 30px;
}
[data-pc-direction=rtl] .task-card li .task-icon {
  right: 3px;
  left: auto;
}
[data-pc-direction=rtl] .task-card .task-list:after {
  right: 10px;
  left: auto;
}
[data-pc-direction=rtl] .task-card .task-list:before {
  right: 3px;
  left: auto;
}
[data-pc-direction=rtl] .new-cust-card .align-middle .status {
  left: 0;
  right: auto;
}
[data-pc-direction=rtl] .apexcharts-legend-marker {
  margin-left: 3px;
}
[data-pc-direction=rtl] .apexcharts-tooltip {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] .btn-group .btn {
  border-radius: var(--bs-btn-border-radius) !important;
}
[data-pc-direction=rtl] .btn-group .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
[data-pc-direction=rtl] .btn-group .btn:not(:first-child) {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
[data-pc-direction=rtl] .btn-group .btn:first-of-type {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
[data-pc-direction=rtl] .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-pc-direction=rtl] .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
[data-pc-direction=rtl] .btn-page .btn-group .btn.btn-outline-secondary:last-child {
  border-left: 1px solid #39465f;
  border-right: none;
}
[data-pc-direction=rtl] .btn-page .btn-group .btn:first-child {
  border-left: none;
}
[data-pc-direction=rtl] .btn-page .btn-group .btn.btn-outline-danger:last-child {
  border-left: 1px solid #dc3545;
}
[data-pc-direction=rtl] .btn-page .btn-group label.btn-outline-secondary:first-of-type {
  border-right: 1px solid #39465f;
  border-left: none;
}
[data-pc-direction=rtl] ul.pagination {
  padding-right: 0;
}
[data-pc-direction=rtl] .page-item:not(:first-child) .page-link {
  margin-left: 0;
  margin-right: -1px;
}
[data-pc-direction=rtl] .page-item:last-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[data-pc-direction=rtl] .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}
[data-pc-direction=rtl] .dropdown-toggle::after {
  margin-left: 0;
  margin-right: 0.255em;
}
[data-pc-direction=rtl] .modal-header .btn-close {
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) auto calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5);
}
[data-pc-direction=rtl] .toast-header .btn-close {
  margin-right: var(--bs-toast-padding-x);
  margin-left: calc(var(--bs-toast-padding-x) * -0.5);
}
[data-pc-direction=rtl] .notifier-container {
  right: auto;
  left: 4px;
}
[data-pc-direction=rtl] .notifier-container .notifier:not(.shown) {
  transform: translateX(-100%);
}
[data-pc-direction=rtl] .vtree,
[data-pc-direction=rtl] pre[class*=language-] > code {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] select.form-control,
[data-pc-direction=rtl] select.custom-select,
[data-pc-direction=rtl] select.dataTable-selector,
[data-pc-direction=rtl] select.dataTable-input,
[data-pc-direction=rtl] .form-select {
  background-position: left 0.75rem center;
}
[data-pc-direction=rtl] .form-select {
  padding-right: 0.75rem;
  padding-left: 2rem;
}
[data-pc-direction=rtl] .form-check {
  padding-left: 0;
  padding-right: 1.75em;
}
[data-pc-direction=rtl] .form-check .form-check-input {
  float: right;
  margin-right: -1.75em;
}
[data-pc-direction=rtl] .address-check .form-check {
  padding-right: 0;
}
[data-pc-direction=rtl] .address-btns {
  justify-content: flex-end !important;
}
[data-pc-direction=rtl] select.custom-select,
[data-pc-direction=rtl] select.dataTable-selector,
[data-pc-direction=rtl] .form-select {
  padding-left: 2rem;
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .form-switch {
  padding-left: 0;
  padding-right: 2.5em;
}
[data-pc-direction=rtl] .form-switch .form-check-input {
  margin-right: -2.5em;
  background-position: right center;
}
[data-pc-direction=rtl] .form-switch .form-check-input:checked {
  background-position: left center;
}
[data-pc-direction=rtl] .custom-switch-v1.form-switch {
  padding-right: 2.9em;
}
[data-pc-direction=rtl] .custom-switch-v1.form-switch .form-check-input {
  margin-right: -2.9em;
}
[data-pc-direction=rtl] .input-group > * {
  border-radius: 6px !important;
}
[data-pc-direction=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
[data-pc-direction=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
[data-pc-direction=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
[data-pc-direction=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
[data-pc-direction=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
[data-pc-direction=rtl] .input-group > .form-floating:not(:first-child) > .form-control,
[data-pc-direction=rtl] .input-group > .form-floating:not(:first-child) > .form-select {
  margin-right: -1px;
  margin-left: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-pc-direction=rtl] .form-floating > label {
  left: auto;
  right: 0;
}
[data-pc-direction=rtl] .form-check-inline {
  margin-right: 0;
  margin-left: 1rem;
}
[data-pc-direction=rtl] .choices[data-type*=select-multiple] .choices__button,
[data-pc-direction=rtl] .choices[data-type*=text] .choices__button {
  margin: 0 8px 0 -4px;
  border-left: none;
  border-right: 1px solid rgba(255, 255, 255, 0.35);
}
[data-pc-direction=rtl] .choices[data-type*=select-one]:after {
  right: auto;
  left: 11.5px;
}
[data-pc-direction=rtl] .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto;
  left: 0;
}
[data-pc-direction=rtl] .editor-toolbar,
[data-pc-direction=rtl] .CodeMirror {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] .dataTable-sorter::before,
[data-pc-direction=rtl] .dataTable-sorter::after {
  right: auto;
  left: 4px;
}
[data-pc-direction=rtl] .dataTable-dropdown label select,
[data-pc-direction=rtl] .datatable-dropdown label select {
  margin-right: 0;
  margin-left: 8px;
}
[data-pc-direction=rtl] .dataTables_length select.form-select {
  padding-right: 0.7rem;
  padding-left: 30px;
}
[data-pc-direction=rtl] .dataTables_scrollHeadInner,
[data-pc-direction=rtl] .dataTables_scrollFootInner {
  padding-right: 0 !important;
  width: calc(100% - 17px) !important;
}
[data-pc-direction=rtl] table.dataTable thead th,
[data-pc-direction=rtl] table.dataTable thead td,
[data-pc-direction=rtl] table.dataTable tfoot th,
[data-pc-direction=rtl] table.dataTable tfoot td {
  text-align: right;
}
[data-pc-direction=rtl] table.dataTable .form-control {
  padding-left: 30px;
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-asc, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-desc, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-asc, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-desc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-asc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-desc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-asc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-desc {
  padding-right: 12px;
  padding-left: 30px;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order {
  right: unset;
  left: 12px;
}
[data-pc-direction=rtl] .dt-scroll-headInner,
[data-pc-direction=rtl] .dt-scroll-footInner {
  padding-right: unset !important;
  padding-left: 17px;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc_disabled:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc_disabled:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc_disabled:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc_disabled:after {
  left: 10px;
  right: unset;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc_disabled,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc_disabled,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc_disabled,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc_disabled {
  padding-right: 0.75rem;
  padding-left: 26px;
}
[data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
  margin-right: 0.5em;
}
[data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}
[data-pc-direction=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
[data-pc-direction=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}
@media (max-width: 767px) {
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_length,
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter,
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_info,
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  padding-left: 0.75rem;
  padding-right: 30px;
}
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  left: auto;
  right: 5px;
}
[data-pc-direction=rtl] .datatable-table > thead > tr > th {
  text-align: right;
}
[data-pc-direction=rtl] .datatable-table .datatable-sorter {
  padding-right: unset;
  padding-left: 18px;
}
[data-pc-direction=rtl] .datatable-table .datatable-sorter::before, [data-pc-direction=rtl] .datatable-table .datatable-sorter::after {
  right: unset;
  left: 4px;
}
[data-pc-direction=rtl] .datatable-top > nav:first-child,
[data-pc-direction=rtl] .datatable-top > div:first-child {
  float: right;
}
[data-pc-direction=rtl] .datatable-top > nav:last-child,
[data-pc-direction=rtl] .datatable-top > div:last-child {
  float: left;
}
[data-pc-direction=rtl] div.dt-container div.dt-length select {
  margin-right: unset;
  margin-left: 0.5em;
}
[data-pc-direction=rtl] div.dt-container div.dt-search {
  text-align: left;
}
[data-pc-direction=rtl] div.dt-container div.dt-search input {
  margin-left: unset;
  margin-right: 0.5em;
}
[data-pc-direction=rtl] .pc-icon-checkbox {
  padding-right: 0;
}
[data-pc-direction=rtl] #cke5-inline-demo .demo-row .demo-row__half:first-of-type {
  padding-right: 0;
  padding-left: 0.5rem;
}
[data-pc-direction=rtl] #cke5-inline-demo .demo-row .demo-row__half:last-of-type {
  padding-right: 0.5rem;
  padding-left: 0;
}
[data-pc-direction=rtl] .mail-wrapper .mail-menulist {
  margin-right: 0;
  margin-left: var(--bs-gutter-x);
}
[data-pc-direction=rtl] .mail-option .mail-buttons {
  right: auto;
  left: 5px;
  transform-origin: left;
}
[data-pc-direction=rtl] .nav {
  padding-right: 0;
}
[data-pc-direction=rtl] .chat-wrapper .chat-userlist {
  margin-right: 0;
  margin-left: var(--bs-gutter-x);
}
[data-pc-direction=rtl] .chat-wrapper .chat-userinfo {
  margin-right: var(--bs-gutter-x);
  margin-left: 0;
}
[data-pc-direction=rtl] .ecom-wrapper .ecom-filter {
  margin-right: var(--bs-gutter-x);
  margin-left: 0;
}
[data-pc-direction=rtl] .ecom-wrapper .ecom-offcanvas.show .ecom-filter {
  margin-right: 0;
}
[data-pc-direction=rtl] .table-card .card-body .table tr td:first-child,
[data-pc-direction=rtl] .table-card .card-body .table tr th:first-child,
[data-pc-direction=rtl] .table-body.card-body .table tr td:first-child,
[data-pc-direction=rtl] .table-body.card-body .table tr th:first-child {
  padding-right: 25px;
  padding-left: 0.75rem;
}
[data-pc-direction=rtl] .table-card .card-body .table tr td:last-child,
[data-pc-direction=rtl] .table-card .card-body .table tr th:last-child,
[data-pc-direction=rtl] .table-body.card-body .table tr td:last-child,
[data-pc-direction=rtl] .table-body.card-body .table tr th:last-child {
  padding-right: 25px;
  padding-left: 0.75rem;
}
@media (max-width: 767.98px) {
  [data-pc-direction=rtl] .order-timeline .timeline-progress:after, [data-pc-direction=rtl] .order-timeline .timeline-progress:before {
    transform: translate(50%);
    left: auto;
    right: 25px;
  }
}
[data-pc-direction=rtl] .mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone {
  margin-left: 0;
  margin-left: 8px;
}
[data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple]):not([size]),
[data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple])[size="1"],
[data-pc-direction=rtl] .form-select.is-valid:not([multiple]):not([size]),
[data-pc-direction=rtl] .form-select.is-valid:not([multiple])[size="1"],
[data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple]):not([size]),
[data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple])[size="1"],
[data-pc-direction=rtl] .form-select.is-invalid:not([multiple]):not([size]),
[data-pc-direction=rtl] .form-select.is-invalid:not([multiple])[size="1"] {
  background-position: left 0.75rem center, center left 2.75rem;
}
[data-pc-direction=rtl] .dataTable-table th a {
  margin-left: 16px;
  padding-right: 0;
}
[data-pc-direction=rtl] .auth-main .auth-wrapper .auth-form img {
  padding-left: 15px;
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-kanban-wrapper .pc-kanban-column:not(:last-child) {
  margin-right: 0;
  margin-left: var(--bs-gutter-x);
}
[data-pc-direction=rtl] #tree-msg {
  text-align: left;
}
[data-pc-direction=rtl] .tns-outer {
  direction: ltr;
}
[data-pc-direction=rtl] .slider {
  direction: ltr;
}
[data-pc-direction=rtl] #BC .slider-handle {
  margin-left: -12px;
  margin-right: auto;
}
[data-pc-direction=rtl] .notifier-close {
  left: 4px;
  right: auto;
}
[data-pc-direction=rtl] .list-group[class*=list-group-horizontal] {
  flex-direction: column;
  justify-content: flex-end;
}
[data-pc-direction=rtl] .list-group.list-group-horizontal {
  flex-direction: row-reverse;
}
@media (min-width: 576px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-sm {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-md {
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-lg {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1200px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-xl {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1400px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-xxl {
    flex-direction: row-reverse;
  }
}
[data-pc-direction=rtl] .commingsoon-wrapper .sideanimation-block .bottom-img {
  transform: rotatey(180deg);
}

[data-pc-theme=dark] {
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --pc-heading-color: rgba(255, 255, 255, 0.8);
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #8492c4;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #6f747f;
  --pc-header-shadow: none;
  --pc-active-background: #282d31;
  --pc-card-box-shadow: 0px 8px 24px rgba(var(--bs-body-bg-rgb), 0.1);
  --pc-header-submenu-background: #383f45;
  --pc-header-submenu-color: var(--bs-body-color);
  --bs-heading-color: #bdc8f0;
  --bs-body-color: #bdc8f0;
  --bs-body-color-rgb: to-rgb(#bdc8f0);
  --bs-border-color: #434b53;
  --ck-color-image-caption-background: #2a2f34;
  --ck-color-image-caption-text: #bfbfbf;
}
[data-pc-theme=dark] .text-muted {
  color: #8492c4 !important;
}
[data-pc-theme=dark] .card {
  --bs-card-border-color: #434b53;
  --bs-card-bg: #2a2f34;
}
[data-pc-theme=dark] .pc-header .pc-mega-menu .pc-mega-dmenu .row.g-0 .col {
  border-right-color: rgb(var(--bs-body-bg-rgb));
}
[data-pc-theme=dark] .pc-header .dropdown-user-profile .settings-block .form-switch .form-check-label {
  color: var(--bs-heading-color);
}
[data-pc-theme=dark] .offcanvas,
[data-pc-theme=dark] .offcanvas-xxl,
[data-pc-theme=dark] .offcanvas-xl,
[data-pc-theme=dark] .offcanvas-lg,
[data-pc-theme=dark] .offcanvas-md,
[data-pc-theme=dark] .offcanvas-sm {
  --bs-offcanvas-bg: #282d31;
}
[data-pc-theme=dark] .list-group {
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: rgba(255, 255, 255, 0.15);
  --bs-list-group-disabled-bg: rgba(0, 0, 0, 0.15);
  --bs-list-group-action-hover-bg: #2c3237;
  --bs-list-group-action-hover-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: #2c3237;
  --bs-list-group-action-active-color: var(--bs-body-color);
}
[data-pc-theme=dark] .preset-btn {
  --bs-gray-300: #2c3237;
}
[data-pc-theme=dark] .progress {
  --bs-progress-bg: var(--bs-body-bg);
}
[data-pc-theme=dark] .btn:not(.btn-light) {
  --bs-btn-color: var(--bs-heading-color);
}
[data-pc-theme=dark] .btn-light-dark,
[data-pc-theme=dark] .btn-link-dark,
[data-pc-theme=dark] .btn-link-secondary,
[data-pc-theme=dark] .bg-light-secondary,
[data-pc-theme=dark] .btn-light-secondary {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .dropdown-menu {
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-bg: #383f45;
  --bs-dropdown-link-hover-bg: #282d31;
  --bs-dropdown-divider-bg: #2c3237;
}
[data-pc-theme=dark] .pagination {
  --bs-pagination-bg: transparent;
  --bs-pagination-border-color: rgba(255, 255, 255, 0.15);
  --bs-pagination-hover-bg: #2f343a;
  --bs-pagination-hover-border-color: #31373d;
  --bs-pagination-focus-bg: #2f343a;
  --bs-pagination-disabled-bg: rgba(0, 0, 0, 0.06);
  --bs-pagination-disabled-border-color: #31373d;
}
[data-pc-theme=dark] .accordion-item {
  --bs-accordion-bg: transparent;
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-border-color: rgba(255, 255, 255, 0.15);
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-active-bg: rgba(var(--bs-primary-rgb), 0.2);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .navbar.navbar-light .navbar-nav {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}
[data-pc-theme=dark] .btn-close.btn-close-white {
  filter: none;
}
[data-pc-theme=dark] .modal {
  --bs-modal-bg: #2a2f34;
  --bs-modal-header-border-color: rgba(255, 255, 255, 0.15);
  --bs-modal-footer-border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .toast {
  --bs-toast-bg: #2a2f34;
  --bs-toast-color: var(--bs-body-color);
  --bs-toast-header-bg: #2f343a;
  --bs-toast-header-color: var(--bs-heading-color);
}
[data-pc-theme=dark] .vtree a.vtree-leaf-label {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .vtree li.vtree-leaf a.vtree-leaf-label:hover,
[data-pc-theme=dark] .vtree li.vtree-leaf.vtree-selected > a.vtree-leaf-label {
  background-color: #333940;
  outline-color: #333940;
}
[data-pc-theme=dark] .custom-select,
[data-pc-theme=dark] .datatable-selector,
[data-pc-theme=dark] .datatable-input,
[data-pc-theme=dark] .form-select,
[data-pc-theme=dark] .form-control {
  background-color: #383f45;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .custom-select:not(:focus),
[data-pc-theme=dark] .datatable-selector:not(:focus),
[data-pc-theme=dark] .datatable-input:not(:focus),
[data-pc-theme=dark] .form-select:not(:focus),
[data-pc-theme=dark] .form-control:not(:focus) {
  border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .custom-select.is-valid,
[data-pc-theme=dark] .datatable-selector.is-valid,
[data-pc-theme=dark] .datatable-input.is-valid,
[data-pc-theme=dark] .form-select.is-valid,
[data-pc-theme=dark] .form-control.is-valid {
  border-color: var(--bs-success);
}
[data-pc-theme=dark] .custom-select.is-invalid,
[data-pc-theme=dark] .datatable-selector.is-invalid,
[data-pc-theme=dark] .datatable-input.is-invalid,
[data-pc-theme=dark] .form-select.is-invalid,
[data-pc-theme=dark] .form-control.is-invalid {
  border-color: var(--bs-danger);
}
[data-pc-theme=dark] .form-control-plaintext {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .form-check-input:not(:checked),
[data-pc-theme=dark] .input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--bs-body-color);
  border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .form-control::file-selector-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--bs-body-color);
  border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark] select.form-control,
[data-pc-theme=dark] select.custom-select,
[data-pc-theme=dark] select.datatable-selector,
[data-pc-theme=dark] select.datatable-input,
[data-pc-theme=dark] .form-select:not([multiple]) {
  background-color: #383f45;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236f747f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
}
[data-pc-theme=dark] .form-range::-webkit-slider-runnable-track {
  background-color: #383f45;
}
[data-pc-theme=dark] .drp-search .form-control {
  background: transparent;
}
[data-pc-theme=dark] .loader {
  background-color: rgba(33, 37, 41, 0.5);
}
[data-pc-theme=dark] .text-dark {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .carousel-dark .carousel-caption h5 {
  color: #000000;
}
[data-pc-theme=dark] .custom-switch-v1.form-check .form-check-input.input-light-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2334495E'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked[type=checkbox],
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .form-floating > .form-control:focus ~ label::after,
[data-pc-theme=dark] .form-floating > .custom-select:focus ~ label::after,
[data-pc-theme=dark] .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
[data-pc-theme=dark] .form-floating > .custom-select:not(:placeholder-shown) ~ label::after,
[data-pc-theme=dark] .form-floating > .form-control-plaintext ~ label::after,
[data-pc-theme=dark] .form-floating > .form-select ~ label::after {
  background: transparent;
}
[data-pc-theme=dark] .card {
  border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .card .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .card .card-header h5,
[data-pc-theme=dark] .card .card-header .h5 {
  color: var(--pc-heading-color);
}
[data-pc-theme=dark] .card .card-footer {
  border-top-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .card .bg-light,
[data-pc-theme=dark] .card .card-footer.bg-light {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
[data-pc-theme=dark] .card .border {
  border: var(--bs-border-width) var(--bs-border-style) rgba(255, 255, 255, 0.15) !important;
}
[data-pc-theme=dark] .flat-card .row-table:first-child {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .flat-card .row-table .br {
  border-right-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .latest-update-card .card-body .latest-update-box:after {
  background: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .task-card .task-list:before,
[data-pc-theme=dark] .task-card .task-list:after {
  background: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .introjs-tooltip,
[data-pc-theme=dark] .notifier,
[data-pc-theme=dark] .datepicker-footer,
[data-pc-theme=dark] .datepicker-picker {
  background-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .datepicker-cell.focused:not(.selected), [data-pc-theme=dark] .datepicker-cell:not(.disabled):hover {
  background: var(--bs-primary);
}
[data-pc-theme=dark] .datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: rgba(var(--bs-primary), 0.3);
}
[data-pc-theme=dark] .datepicker-cell.range {
  background: #2c3237;
}
[data-pc-theme=dark] .datepicker-controls .btn {
  background: transparent;
  border: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .i-main .i-block {
  border-color: rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark] .material-icons-two-tone:not([class*=text]) {
  background-color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar {
  background: var(--bs-body-bg);
  box-shadow: none;
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-month,
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  color: var(--bs-body-color);
  fill: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar span.flatpickr-weekday {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.flatpickr-disabled, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed.prevMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--bs-body-color);
  opacity: 0.3;
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day:focus, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay:focus, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay:focus {
  background: #434b53;
  border-color: #434b53;
  box-shadow: -5px 0 0 #434b53, 5px 0 0 #434b53;
}
[data-pc-theme=dark] .flatpickr-calendar.arrowTop:after, [data-pc-theme=dark] .flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .flatpickr-calendar.arrowBottom:after, [data-pc-theme=dark] .flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .flatpickr-calendar.hasTime .flatpickr-time {
  border-top: none;
}
[data-pc-theme=dark] .flatpickr-time input,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-time input:focus, [data-pc-theme=dark] .flatpickr-time input:hover,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm:focus,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm:hover {
  background: #434b53;
}
[data-pc-theme=dark] .flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--bs-body-color);
}
[data-pc-theme=dark] .choices__inner {
  border-color: rgba(255, 255, 255, 0.15);
  background: transparent;
}
[data-pc-theme=dark] .choices__inner .choices__input {
  background: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .choices[data-type*=select-one]:after {
  border-color: var(--bs-body-color) transparent transparent transparent;
}
[data-pc-theme=dark] .choices[data-type*=select-one] .choices__input {
  background-color: transparent;
  border-bottom: none;
}
[data-pc-theme=dark] .choices.is-disabled .choices__inner,
[data-pc-theme=dark] .choices.is-disabled .choices__input {
  background: transparent;
}
[data-pc-theme=dark] .choices__list--dropdown {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .noUi-target {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .noUi-handle {
  background: var(--bs-body-bg);
  border-color: rgba(255, 255, 255, 0.15);
  box-shadow: none;
}
[data-pc-theme=dark] .noUi-handle:after, [data-pc-theme=dark] .noUi-handle:before {
  background: var(--bs-body-color);
}
[data-pc-theme=dark] .noUi-tooltip {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .typeahead > ul {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .ql-container.ql-snow,
[data-pc-theme=dark] .ql-toolbar.ql-snow {
  border-color: #434b53;
}
[data-pc-theme=dark] .ql-snow .ql-picker {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .ql-snow .ql-stroke {
  stroke: var(--bs-body-color);
}
[data-pc-theme=dark] .ck {
  --ck-color-base-background: var(--bs-body-bg);
  --ck-color-toolbar-background: var(--bs-body-bg);
  --ck-color-base-border: #434b53;
  --ck-color-toolbar-border: #434b53;
  --ck-color-dropdown-panel-border: rgba(0, 0, 0, 0.5);
  --ck-color-button-default-background: transparent;
  --ck-color-text: var(--bs-body-color);
  --ck-color-list-background: var(--bs-body-bg);
  --ck-color-button-default-hover-background: #434b53;
  --ck-color-button-default-active-background: #434b53;
  --ck-color-button-on-active-background: #434b53;
  --ck-color-button-on-background: #434b53;
  --ck-color-button-on-hover-background: #434b53;
  --ck-color-list-button-hover-background: #434b53;
  --ck-color-dropdown-panel-background: var(--bs-body-bg);
  --ck-color-input-background: var(--bs-body-bg);
  --ck-color-panel-background: var(--bs-body-bg);
  --ck-color-panel-border: #434b53;
}
[data-pc-theme=dark] .ck.ck-editor__editable.ck-editor__editable_inline {
  background: var(--bs-body-bg) !important;
  border-color: #434b53;
}
[data-pc-theme=dark] #cke5-inline-demo .ck-content {
  border-color: #434b53;
}
[data-pc-theme=dark] .editor-toolbar,
[data-pc-theme=dark] .CodeMirror {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border-color: #434b53;
}
[data-pc-theme=dark] .editor-toolbar i.separator {
  border-left-color: #434b53;
  border-right-color: #434b53;
}
[data-pc-theme=dark] .editor-toolbar a {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .editor-toolbar a.active, [data-pc-theme=dark] .editor-toolbar a:hover {
  background: #434b53;
  border-color: #434b53;
}
[data-pc-theme=dark] .dropzone {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark] .uppy-Dashboard-inner,
[data-pc-theme=dark] .uppy-DragDrop-container {
  background: var(--bs-body-bg);
  border-color: rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark] .uppy-DashboardTab-btn:hover {
  background: #434b53;
}
[data-pc-theme=dark] .uppy-DashboardTab-btn,
[data-pc-theme=dark] .uppy-Dashboard-AddFiles-title {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  border-color: #434b53;
}
[data-pc-theme=dark] .uppy-StatusBar,
[data-pc-theme=dark] .uppy-DashboardContent-bar {
  border: none;
  color: var(--bs-body-color);
  background: #434b53;
}
[data-pc-theme=dark] .datatable-table,
[data-pc-theme=dark] .table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-border-color: #434b53;
}
[data-pc-theme=dark] .datatable-table thead th,
[data-pc-theme=dark] .table thead th {
  color: var(--bs-body-color);
  background: rgba(255, 255, 255, 0.07);
  border-color: rgba(255, 255, 255, 0.07);
}
[data-pc-theme=dark] .datatable-table > :not(:last-child) > :last-child > *,
[data-pc-theme=dark] .datatable-table td,
[data-pc-theme=dark] .datatable-table th,
[data-pc-theme=dark] .table > :not(:last-child) > :last-child > *,
[data-pc-theme=dark] .table td,
[data-pc-theme=dark] .table th {
  border-color: rgba(255, 255, 255, 0.07);
}
[data-pc-theme=dark] .datatable-table[class*=bg-] > :not(caption) > * > *,
[data-pc-theme=dark] .table[class*=bg-] > :not(caption) > * > * {
  color: #fff;
}
[data-pc-theme=dark] .datatable-table::not([class*="bg-"]) > :not(caption) > * > *,
[data-pc-theme=dark] .table::not([class*="bg-"]) > :not(caption) > * > * {
  background-color: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: transparent;
}
[data-pc-theme=dark] .datatable-pagination a {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .datatable-pagination a:hover {
  background: #434b53;
}
[data-pc-theme=dark] .datatable-pagination .active a {
  color: #fff;
}
[data-pc-theme=dark] .datatable-sorter::after {
  border-bottom-color: var(--bs-body-color);
}
[data-pc-theme=dark] .datatable-sorter::before {
  border-top-color: var(--bs-body-color);
}
[data-pc-theme=dark] .dtfh-floatingparentfoot table th {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] table.dataTable tbody tr > .dtfc-fixed-left,
[data-pc-theme=dark] table.dataTable tbody tr > .dtfc-fixed-right,
[data-pc-theme=dark] div.dataTables_scrollBody > table > tbody tr:first-child th,
[data-pc-theme=dark] div.dataTables_scrollBody > table > tbody tr:first-child td,
[data-pc-theme=dark] .datatable-table.dataTable[class*=table-] thead th,
[data-pc-theme=dark] .table.dataTable[class*=table-] thead th,
[data-pc-theme=dark] table.datatable.fixedHeader-floating,
[data-pc-theme=dark] table.datatable.fixedHeader-locked,
[data-pc-theme=dark] div.DTFC_LeftHeadWrapper table,
[data-pc-theme=dark] div.DTFC_RightHeadWrapper table,
[data-pc-theme=dark] table.DTFC_Cloned tr {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] table.dataTable thead tr > .dtfc-fixed-left,
[data-pc-theme=dark] table.dataTable thead tr > .dtfc-fixed-right,
[data-pc-theme=dark] table.dataTable tfoot tr > .dtfc-fixed-left,
[data-pc-theme=dark] table.dataTable tfoot tr > .dtfc-fixed-right,
[data-pc-theme=dark] .table.datatable[class*=table-] thead th {
  background: #434b53;
}
[data-pc-theme=dark] .table-bordered > :not(caption) > * {
  border-width: 0px;
}
[data-pc-theme=dark] table.datatable > tbody > tr.child ul.dtr-details > li {
  border-bottom-color: #434b53;
}
[data-pc-theme=dark] .apexcharts-legend-text {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] text {
  fill: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .apexcharts-datalabels text,
[data-pc-theme=dark] .apexcharts-data-labels text {
  fill: #fff !important;
}
[data-pc-theme=dark] .apexcharts-canvas line {
  stroke: transparent !important;
}
[data-pc-theme=dark] .apexcharts-tooltip.apexcharts-theme-light {
  background: var(--bs-body-bg);
  border-color: #434b53;
}
[data-pc-theme=dark] .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--bs-body-bg);
  border-bottom-color: #434b53;
}
[data-pc-theme=dark] .fc .fc-list-sticky .fc-list-day > *,
[data-pc-theme=dark] .fc .fc-scrollgrid-section-sticky > *,
[data-pc-theme=dark] .auth-main .auth-wrapper.v3,
[data-pc-theme=dark] .auth-main .auth-wrapper.v2 {
  background: #2a2f34;
}
[data-pc-theme=dark] .table-bordered td,
[data-pc-theme=dark] .table-bordered th,
[data-pc-theme=dark] .table-bordered {
  border-color: #434b53;
}
[data-pc-theme=dark] .contact-form.bg-white {
  background: #2a2f34 !important;
}
[data-pc-theme=dark] .fc-event.event-primary {
  background: rgba(13, 110, 253, 0.2) !important;
  color: #0d6efd !important;
}
[data-pc-theme=dark] .fc-event.event-primary.fc-h-event .fc-event-main {
  color: #0d6efd;
}
[data-pc-theme=dark] .fc-event.event-primary.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-primary.fc-h-event:hover {
  background: #0d6efd;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-secondary {
  background: rgba(102, 16, 242, 0.2) !important;
  color: #6610f2 !important;
}
[data-pc-theme=dark] .fc-event.event-secondary.fc-h-event .fc-event-main {
  color: #6610f2;
}
[data-pc-theme=dark] .fc-event.event-secondary.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-secondary.fc-h-event:hover {
  background: #6610f2;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-success {
  background: rgba(25, 135, 84, 0.2) !important;
  color: #198754 !important;
}
[data-pc-theme=dark] .fc-event.event-success.fc-h-event .fc-event-main {
  color: #198754;
}
[data-pc-theme=dark] .fc-event.event-success.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-success.fc-h-event:hover {
  background: #198754;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-info {
  background: rgba(13, 202, 240, 0.2) !important;
  color: #0dcaf0 !important;
}
[data-pc-theme=dark] .fc-event.event-info.fc-h-event .fc-event-main {
  color: #0dcaf0;
}
[data-pc-theme=dark] .fc-event.event-info.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-info.fc-h-event:hover {
  background: #0dcaf0;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-warning {
  background: rgba(255, 193, 7, 0.2) !important;
  color: #ffc107 !important;
}
[data-pc-theme=dark] .fc-event.event-warning.fc-h-event .fc-event-main {
  color: #ffc107;
}
[data-pc-theme=dark] .fc-event.event-warning.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-warning.fc-h-event:hover {
  background: #ffc107;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-danger {
  background: rgba(220, 53, 69, 0.2) !important;
  color: #dc3545 !important;
}
[data-pc-theme=dark] .fc-event.event-danger.fc-h-event .fc-event-main {
  color: #dc3545;
}
[data-pc-theme=dark] .fc-event.event-danger.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-danger.fc-h-event:hover {
  background: #dc3545;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-light {
  background: rgba(248, 249, 250, 0.2) !important;
  color: #f8f9fa !important;
}
[data-pc-theme=dark] .fc-event.event-light.fc-h-event .fc-event-main {
  color: #f8f9fa;
}
[data-pc-theme=dark] .fc-event.event-light.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-light.fc-h-event:hover {
  background: #f8f9fa;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-dark {
  background: rgba(17, 25, 54, 0.2) !important;
  color: #111936 !important;
}
[data-pc-theme=dark] .fc-event.event-dark.fc-h-event .fc-event-main {
  color: #111936;
}
[data-pc-theme=dark] .fc-event.event-dark.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-dark.fc-h-event:hover {
  background: #111936;
  color: #fff;
}
[data-pc-theme=dark] .btns-gallery .btn-light-primary:not(:hover), [data-pc-theme=dark] .btns-gallery .btn-light-primary:not(:focus), [data-pc-theme=dark] .btns-gallery .btn-light-primary:not(:active), [data-pc-theme=dark] .btns-gallery .btn-light-primary:not(.active) {
  background: transparent !important;
  border-color: transparent;
  color: #65727e;
}
[data-pc-theme=dark] .btns-gallery .btn-light-primary.active {
  background: var(--bs-primary) !important;
}
[data-pc-theme=dark] .jvm-container path {
  fill: #434b53;
}
[data-pc-theme=dark] .mail-wrapper .mail-table tr:not(.unread) {
  background: rgba(0, 0, 0, 0.3);
}
[data-pc-theme=dark] .product-card .btn-prod-card {
  border-color: rgba(255, 255, 255, 0.4);
}
[data-pc-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .uppy-DragDrop-label,
[data-pc-theme=dark] .uppy-StatusBar-content,
[data-pc-theme=dark] .uppy-Root {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .playbutton-wrapper [data-action],
[data-pc-theme=dark] .tns-outer [data-action] {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .slider.slider-disabled .slider-track,
[data-pc-theme=dark] .slider-track {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] div:where(.swal2-container) .swal2-range {
  background: var(--bs-body-bg) !important;
}
[data-pc-theme=dark] .modal-body .bd-example-row {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .auth-main .auth-wrapper .saprator:after {
  background: rgba(255, 255, 255, 0.2);
}
[data-pc-theme=dark].component-page .footer .footer-link a:not(:hover) {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .badge.bg-light-primary {
  background: rgba(13, 110, 253, 0.2);
  color: #0d6efd;
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .icon-svg-primary {
  fill: rgba(13, 110, 253, 0.2);
  stroke: #0d6efd;
}
[data-pc-theme=dark] .bg-light-primary {
  background: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .btn-light-primary:not(:hover) {
  background: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .btn-link-primary:hover {
  background: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .alert-primary {
  color: #0d6efd;
  background: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .alert-primary .alert-link {
  color: #0d6efd;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(13, 110, 253, 0.2);
  background-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .fc-event.event-primary {
  background: rgba(13, 110, 253, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-secondary {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark] .icon-svg-secondary {
  fill: rgba(102, 16, 242, 0.2);
  stroke: #6610f2;
}
[data-pc-theme=dark] .bg-light-secondary {
  background: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark] .btn-light-secondary:not(:hover) {
  background: rgba(102, 16, 242, 0.2);
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark] .btn-link-secondary:hover {
  background: rgba(102, 16, 242, 0.2);
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark] .alert-secondary {
  color: #6610f2;
  background: rgba(102, 16, 242, 0.2);
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark] .alert-secondary .alert-link {
  color: #6610f2;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(102, 16, 242, 0.2);
  background-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark] .fc-event.event-secondary {
  background: rgba(102, 16, 242, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-success {
  background: rgba(25, 135, 84, 0.2);
  color: #198754;
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .icon-svg-success {
  fill: rgba(25, 135, 84, 0.2);
  stroke: #198754;
}
[data-pc-theme=dark] .bg-light-success {
  background: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .btn-light-success:not(:hover) {
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .btn-link-success:hover {
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .alert-success {
  color: #198754;
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .alert-success .alert-link {
  color: #198754;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-success:checked {
  border-color: rgba(25, 135, 84, 0.2);
  background-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .fc-event.event-success {
  background: rgba(25, 135, 84, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-info {
  background: rgba(13, 202, 240, 0.2);
  color: #0dcaf0;
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .icon-svg-info {
  fill: rgba(13, 202, 240, 0.2);
  stroke: #0dcaf0;
}
[data-pc-theme=dark] .bg-light-info {
  background: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .btn-light-info:not(:hover) {
  background: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .btn-link-info:hover {
  background: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .alert-info {
  color: #0dcaf0;
  background: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .alert-info .alert-link {
  color: #0dcaf0;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-info:checked {
  border-color: rgba(13, 202, 240, 0.2);
  background-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .fc-event.event-info {
  background: rgba(13, 202, 240, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-warning {
  background: rgba(255, 193, 7, 0.2);
  color: #ffc107;
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .icon-svg-warning {
  fill: rgba(255, 193, 7, 0.2);
  stroke: #ffc107;
}
[data-pc-theme=dark] .bg-light-warning {
  background: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .btn-light-warning:not(:hover) {
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .btn-link-warning:hover {
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .alert-warning {
  color: #ffc107;
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .alert-warning .alert-link {
  color: #ffc107;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-warning:checked {
  border-color: rgba(255, 193, 7, 0.2);
  background-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .fc-event.event-warning {
  background: rgba(255, 193, 7, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-danger {
  background: rgba(220, 53, 69, 0.2);
  color: #dc3545;
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .icon-svg-danger {
  fill: rgba(220, 53, 69, 0.2);
  stroke: #dc3545;
}
[data-pc-theme=dark] .bg-light-danger {
  background: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .btn-light-danger:not(:hover) {
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .btn-link-danger:hover {
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .alert-danger {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .alert-danger .alert-link {
  color: #dc3545;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-danger:checked {
  border-color: rgba(220, 53, 69, 0.2);
  background-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .fc-event.event-danger {
  background: rgba(220, 53, 69, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-light {
  background: rgba(248, 249, 250, 0.2);
  color: #f8f9fa;
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .icon-svg-light {
  fill: rgba(248, 249, 250, 0.2);
  stroke: #f8f9fa;
}
[data-pc-theme=dark] .bg-light-light {
  background: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .btn-light-light:not(:hover) {
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .btn-link-light:hover {
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .alert-light {
  color: #f8f9fa;
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .alert-light .alert-link {
  color: #f8f9fa;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-light:checked {
  border-color: rgba(248, 249, 250, 0.2);
  background-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .fc-event.event-light {
  background: rgba(248, 249, 250, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-dark {
  background: rgba(17, 25, 54, 0.2);
  color: #111936;
  border-color: rgba(17, 25, 54, 0.2);
}
[data-pc-theme=dark] .icon-svg-dark {
  fill: rgba(17, 25, 54, 0.2);
  stroke: #111936;
}
[data-pc-theme=dark] .bg-light-dark {
  background: rgba(17, 25, 54, 0.2);
}
[data-pc-theme=dark] .btn-light-dark:not(:hover) {
  background: rgba(17, 25, 54, 0.2);
  border-color: rgba(17, 25, 54, 0.2);
}
[data-pc-theme=dark] .btn-link-dark:hover {
  background: rgba(17, 25, 54, 0.2);
  border-color: rgba(17, 25, 54, 0.2);
}
[data-pc-theme=dark] .alert-dark {
  color: #111936;
  background: rgba(17, 25, 54, 0.2);
  border-color: rgba(17, 25, 54, 0.2);
}
[data-pc-theme=dark] .alert-dark .alert-link {
  color: #111936;
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked {
  border-color: rgba(17, 25, 54, 0.2);
  background-color: rgba(17, 25, 54, 0.2);
}
[data-pc-theme=dark] .fc-event.event-dark {
  background: rgba(17, 25, 54, 0.2) !important;
}
[data-pc-theme=dark] .badge.bg-light-dark,
[data-pc-theme=dark] .alert-dark .alert-link,
[data-pc-theme=dark] .alert-dark {
  color: var(--bs-body-color);
}
[data-pc-theme=dark][data-pc-preset=preset-1] {
  --bs-dark-body-bg: #111936;
  --bs-primary: #2196f3;
  --bs-body-bg: #111936;
  background: #172249;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .bg-primary-dark {
  background: #1c76da;
  color: #1c76da;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .bg-secondary-dark {
  background: #542ca7;
  color: #542ca7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .frameworks-card {
  background: #131d3e !important;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .testaments-cards::after {
  background: linear-gradient(0deg, #172249, transparent);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .dropdown-menu {
  --bs-dropdown-bg: #1d2b5d;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination a,
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination .datatable-active a, [data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination .datatable-active button,
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-pagination .datatable-active button:hover {
  background-color: #1d2b5d;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .offcanvas,
[data-pc-theme=dark][data-pc-preset=preset-1] .offcanvas-xxl,
[data-pc-theme=dark][data-pc-preset=preset-1] .offcanvas-xl,
[data-pc-theme=dark][data-pc-preset=preset-1] .offcanvas-lg,
[data-pc-theme=dark][data-pc-preset=preset-1] .offcanvas-md,
[data-pc-theme=dark][data-pc-preset=preset-1] .offcanvas-sm {
  --bs-offcanvas-bg: #172249;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .pc-container {
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .bg-body {
  background: #111936 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .auth-wrapper.v2 {
  background: #172249;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .form-control,
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-input,
[data-pc-theme=dark][data-pc-preset=preset-1] .sticky-action,
[data-pc-theme=dark][data-pc-preset=preset-1] .card:not([class*=bg-]),
[data-pc-theme=dark][data-pc-preset=preset-1] .page-header,
[data-pc-theme=dark][data-pc-preset=preset-1] .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-1] .pc-sidebar {
  background: #172249;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .card {
  --bs-card-bg: lighten($pc-body, 5%);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .datatable-selector,
[data-pc-theme=dark][data-pc-preset=preset-1] .dataTable-selector,
[data-pc-theme=dark][data-pc-preset=preset-1] .custom-select,
[data-pc-theme=dark][data-pc-preset=preset-1] .form-select {
  background-color: #172249;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .pct-customizer .pct-c-content {
  background: #18244d;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .pct-customizer .pct-c-content {
  box-shadow: -1px 0 1px 0px rgba(17, 25, 54, 0.5);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary {
  background: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary {
  background: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .pc-header .dropdown-notification .list-group-item-action:active, [data-pc-theme=dark][data-pc-preset=preset-1] .pc-header .dropdown-notification .list-group-item-action:hover, [data-pc-theme=dark][data-pc-preset=preset-1] .pc-header .dropdown-notification .list-group-item-action:focus {
  background: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  background: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu {
  background: #172249;
}
[data-pc-theme=dark][data-pc-preset=preset-1][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item.active > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-1][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:focus > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-1][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:hover > .pc-link {
  color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .page-link:hover {
  background: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .list-group {
  --bs-list-group-action-hover-bg: rgba(33, 150, 243, 0.2);
  --bs-list-group-action-hover-color: #2196f3;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .modal {
  --bs-modal-bg: #172249;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .toast {
  --bs-toast-bg: #172249;
  --bs-toast-header-bg: #1a2651;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .swal2-popup {
  background: #1a2651;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .accordion-button:not(.collapsed) {
  color: #673ab7;
  background: rgba(103, 58, 183, 0.1);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem transparentize($pc-secondary, 0.75);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .dropdown-item:hover,
[data-pc-theme=dark][data-pc-preset=preset-1] .dropdown-item:focus {
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: transparentize($pc-secondary, 0.8);
  color: var(--bs-dropdown-link-color);
  background: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item .nav-link.active, [data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item.show .nav-link,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item .nav-link.active,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item.show .nav-link {
  color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item .nav-link.active .material-icons-two-tone, [data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item .nav-link.active .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item.show .nav-link .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item .nav-link.active::after, [data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item.show .nav-link::after,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item .nav-link.active::after,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item.show .nav-link::after {
  background: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item .nav-link:hover,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item .nav-link:hover {
  color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .kanban-tabs .nav-item .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-1] .profile-tabs .nav-item .nav-link:hover .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .account-tabs .nav-item.show .nav-link h5,
[data-pc-theme=dark][data-pc-preset=preset-1] .account-tabs .nav-link:hover h5,
[data-pc-theme=dark][data-pc-preset=preset-1] .account-tabs .nav-link.active h5 {
  color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .account-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-1] .account-tabs .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-1] .account-tabs .nav-link.active .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .mail-option .mail-buttons {
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .auth-main .auth-wrapper.v1 .auth-form {
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .auth-main .auth-wrapper.v2 .auth-sidecontent {
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .auth-main .auth-wrapper.v3 .auth-form {
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .auth-main .auth-wrapper .saprator:after {
  background: #233470;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .auth-main .auth-wrapper .saprator span {
  color: var(--bs-body-color);
  background: #172249;
  outline-color: #233470;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .price-card h2::after,
[data-pc-theme=dark][data-pc-preset=preset-1] .price-card .h2::after {
  background: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .price-card.active {
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-1].layout-2 {
  --pc-sidebar-background: #111936;
  --pc-header-background: #111936;
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1].layout-2 .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-1].layout-2 .pc-sidebar {
  background: #111936;
}
[data-pc-theme=dark][data-pc-preset=preset-1].layout-2 .pc-container {
  background: #151e42;
}
[data-pc-theme=dark][data-pc-preset=preset-1].layout-2 .pc-container .page-header,
[data-pc-theme=dark][data-pc-preset=preset-1].layout-2 .pc-container .card {
  background: #172249;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(33, 150, 243, 0.2);
  background-color: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .alert-primary {
  color: #2196f3;
  background: rgba(33, 150, 243, 0.2);
  border-color: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .alert-primary .alert-link {
  color: #2196f3;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .badge.bg-light-primary {
  background: rgba(33, 150, 243, 0.2);
  color: #2196f3;
  border-color: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .icon-svg-primary {
  fill: rgba(33, 150, 243, 0.2);
  stroke: #2196f3;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .bg-light-primary {
  background: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:not(:hover) {
  background: rgba(33, 150, 243, 0.2);
  border-color: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:hover {
  background: rgba(33, 150, 243, 0.2);
  border-color: rgba(33, 150, 243, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(103, 58, 183, 0.2);
  background-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .alert-secondary {
  color: #673ab7;
  background: rgba(103, 58, 183, 0.2);
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .alert-secondary .alert-link {
  color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .badge.bg-light-secondary {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .icon-svg-secondary {
  fill: rgba(103, 58, 183, 0.2);
  stroke: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .bg-light-secondary {
  background: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-secondary:not(:hover) {
  background: rgba(103, 58, 183, 0.2);
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-secondary:hover {
  background: rgba(103, 58, 183, 0.2);
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] {
  --bs-dark-body-bg: #0e1b23;
  --bs-primary: #607d8b;
  --bs-body-bg: #0e1b23;
  background: #152935;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .bg-primary-dark {
  background: #4e6a78;
  color: #4e6a78;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .bg-secondary-dark {
  background: #008375;
  color: #008375;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .frameworks-card {
  background: #11212a !important;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .testaments-cards::after {
  background: linear-gradient(0deg, #152935, transparent);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .dropdown-menu {
  --bs-dropdown-bg: #1d3747;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination a,
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination .datatable-active a, [data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination .datatable-active button,
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-pagination .datatable-active button:hover {
  background-color: #1d3747;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .offcanvas,
[data-pc-theme=dark][data-pc-preset=preset-2] .offcanvas-xxl,
[data-pc-theme=dark][data-pc-preset=preset-2] .offcanvas-xl,
[data-pc-theme=dark][data-pc-preset=preset-2] .offcanvas-lg,
[data-pc-theme=dark][data-pc-preset=preset-2] .offcanvas-md,
[data-pc-theme=dark][data-pc-preset=preset-2] .offcanvas-sm {
  --bs-offcanvas-bg: #152935;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .pc-container {
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .bg-body {
  background: #0e1b23 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .auth-wrapper.v2 {
  background: #152935;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .form-control,
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-input,
[data-pc-theme=dark][data-pc-preset=preset-2] .sticky-action,
[data-pc-theme=dark][data-pc-preset=preset-2] .card:not([class*=bg-]),
[data-pc-theme=dark][data-pc-preset=preset-2] .page-header,
[data-pc-theme=dark][data-pc-preset=preset-2] .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-2] .pc-sidebar {
  background: #152935;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .card {
  --bs-card-bg: lighten($pc-body, 5%);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .datatable-selector,
[data-pc-theme=dark][data-pc-preset=preset-2] .dataTable-selector,
[data-pc-theme=dark][data-pc-preset=preset-2] .custom-select,
[data-pc-theme=dark][data-pc-preset=preset-2] .form-select {
  background-color: #152935;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .pct-customizer .pct-c-content {
  background: #172c39;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .pct-customizer .pct-c-content {
  box-shadow: -1px 0 1px 0px rgba(14, 27, 35, 0.5);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-primary {
  background: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .pc-header .pc-head-link.head-link-secondary {
  background: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .pc-header .dropdown-notification .list-group-item-action:active, [data-pc-theme=dark][data-pc-preset=preset-2] .pc-header .dropdown-notification .list-group-item-action:hover, [data-pc-theme=dark][data-pc-preset=preset-2] .pc-header .dropdown-notification .list-group-item-action:focus {
  background: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  background: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu {
  background: #152935;
}
[data-pc-theme=dark][data-pc-preset=preset-2][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item.active > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-2][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:focus > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-2][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:hover > .pc-link {
  color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .page-link:hover {
  background: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .list-group {
  --bs-list-group-action-hover-bg: rgba(96, 125, 139, 0.2);
  --bs-list-group-action-hover-color: #607d8b;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .modal {
  --bs-modal-bg: #152935;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .toast {
  --bs-toast-bg: #152935;
  --bs-toast-header-bg: #182f3d;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .swal2-popup {
  background: #182f3d;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .accordion-button:not(.collapsed) {
  color: #009688;
  background: rgba(0, 150, 136, 0.1);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem transparentize($pc-secondary, 0.75);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .dropdown-item:hover,
[data-pc-theme=dark][data-pc-preset=preset-2] .dropdown-item:focus {
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: transparentize($pc-secondary, 0.8);
  color: var(--bs-dropdown-link-color);
  background: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item .nav-link.active, [data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item.show .nav-link,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item .nav-link.active,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item.show .nav-link {
  color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item .nav-link.active .material-icons-two-tone, [data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item .nav-link.active .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item.show .nav-link .material-icons-two-tone {
  background-color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item .nav-link.active::after, [data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item.show .nav-link::after,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item .nav-link.active::after,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item.show .nav-link::after {
  background: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item .nav-link:hover,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item .nav-link:hover {
  color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .kanban-tabs .nav-item .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-2] .profile-tabs .nav-item .nav-link:hover .material-icons-two-tone {
  background-color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .account-tabs .nav-item.show .nav-link h5,
[data-pc-theme=dark][data-pc-preset=preset-2] .account-tabs .nav-link:hover h5,
[data-pc-theme=dark][data-pc-preset=preset-2] .account-tabs .nav-link.active h5 {
  color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .account-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-2] .account-tabs .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-2] .account-tabs .nav-link.active .material-icons-two-tone {
  background-color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .mail-option .mail-buttons {
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .auth-main .auth-wrapper.v1 .auth-form {
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .auth-main .auth-wrapper.v2 .auth-sidecontent {
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .auth-main .auth-wrapper.v3 .auth-form {
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .auth-main .auth-wrapper .saprator:after {
  background: #24455a;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .auth-main .auth-wrapper .saprator span {
  color: var(--bs-body-color);
  background: #152935;
  outline-color: #24455a;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .price-card h2::after,
[data-pc-theme=dark][data-pc-preset=preset-2] .price-card .h2::after {
  background: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .price-card.active {
  border-color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-2].layout-2 {
  --pc-sidebar-background: #0e1b23;
  --pc-header-background: #0e1b23;
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2].layout-2 .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-2].layout-2 .pc-sidebar {
  background: #0e1b23;
}
[data-pc-theme=dark][data-pc-preset=preset-2].layout-2 .pc-container {
  background: #12232e;
}
[data-pc-theme=dark][data-pc-preset=preset-2].layout-2 .pc-container .page-header,
[data-pc-theme=dark][data-pc-preset=preset-2].layout-2 .pc-container .card {
  background: #152935;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(96, 125, 139, 0.2);
  background-color: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .alert-primary {
  color: #607d8b;
  background: rgba(96, 125, 139, 0.2);
  border-color: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .alert-primary .alert-link {
  color: #607d8b;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .badge.bg-light-primary {
  background: rgba(96, 125, 139, 0.2);
  color: #607d8b;
  border-color: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .icon-svg-primary {
  fill: rgba(96, 125, 139, 0.2);
  stroke: #607d8b;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .bg-light-primary {
  background: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:not(:hover) {
  background: rgba(96, 125, 139, 0.2);
  border-color: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:hover {
  background: rgba(96, 125, 139, 0.2);
  border-color: rgba(96, 125, 139, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(0, 150, 136, 0.2);
  background-color: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .alert-secondary {
  color: #009688;
  background: rgba(0, 150, 136, 0.2);
  border-color: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .alert-secondary .alert-link {
  color: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .badge.bg-light-secondary {
  background: rgba(0, 150, 136, 0.2);
  color: #009688;
  border-color: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .icon-svg-secondary {
  fill: rgba(0, 150, 136, 0.2);
  stroke: #009688;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .bg-light-secondary {
  background: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-secondary:not(:hover) {
  background: rgba(0, 150, 136, 0.2);
  border-color: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-secondary:hover {
  background: rgba(0, 150, 136, 0.2);
  border-color: rgba(0, 150, 136, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] {
  --bs-dark-body-bg: #0a0f23;
  --bs-primary: #203461;
  --bs-body-bg: #0a0f23;
  background: #101837;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .bg-primary-dark {
  background: #18274f;
  color: #18274f;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .bg-secondary-dark {
  background: #e73267;
  color: #e73267;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .frameworks-card {
  background: #0c122b !important;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .testaments-cards::after {
  background: linear-gradient(0deg, #101837, transparent);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .dropdown-menu {
  --bs-dropdown-bg: #15204b;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination a,
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination .datatable-active a, [data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination .datatable-active button,
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-pagination .datatable-active button:hover {
  background-color: #15204b;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .offcanvas,
[data-pc-theme=dark][data-pc-preset=preset-3] .offcanvas-xxl,
[data-pc-theme=dark][data-pc-preset=preset-3] .offcanvas-xl,
[data-pc-theme=dark][data-pc-preset=preset-3] .offcanvas-lg,
[data-pc-theme=dark][data-pc-preset=preset-3] .offcanvas-md,
[data-pc-theme=dark][data-pc-preset=preset-3] .offcanvas-sm {
  --bs-offcanvas-bg: #101837;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .pc-container {
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .bg-body {
  background: #0a0f23 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .auth-wrapper.v2 {
  background: #101837;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .form-control,
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-input,
[data-pc-theme=dark][data-pc-preset=preset-3] .sticky-action,
[data-pc-theme=dark][data-pc-preset=preset-3] .card:not([class*=bg-]),
[data-pc-theme=dark][data-pc-preset=preset-3] .page-header,
[data-pc-theme=dark][data-pc-preset=preset-3] .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-3] .pc-sidebar {
  background: #101837;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .card {
  --bs-card-bg: lighten($pc-body, 5%);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .datatable-selector,
[data-pc-theme=dark][data-pc-preset=preset-3] .dataTable-selector,
[data-pc-theme=dark][data-pc-preset=preset-3] .custom-select,
[data-pc-theme=dark][data-pc-preset=preset-3] .form-select {
  background-color: #101837;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .pct-customizer .pct-c-content {
  background: #11193b;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .pct-customizer .pct-c-content {
  box-shadow: -1px 0 1px 0px rgba(10, 15, 35, 0.5);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-primary {
  background: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .pc-header .pc-head-link.head-link-secondary {
  background: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .pc-header .dropdown-notification .list-group-item-action:active, [data-pc-theme=dark][data-pc-preset=preset-3] .pc-header .dropdown-notification .list-group-item-action:hover, [data-pc-theme=dark][data-pc-preset=preset-3] .pc-header .dropdown-notification .list-group-item-action:focus {
  background: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  background: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu {
  background: #101837;
}
[data-pc-theme=dark][data-pc-preset=preset-3][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item.active > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-3][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:focus > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-3][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:hover > .pc-link {
  color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .page-link:hover {
  background: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .list-group {
  --bs-list-group-action-hover-bg: rgba(32, 52, 97, 0.2);
  --bs-list-group-action-hover-color: #203461;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .modal {
  --bs-modal-bg: #101837;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .toast {
  --bs-toast-bg: #101837;
  --bs-toast-header-bg: #121b3f;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .swal2-popup {
  background: #121b3f;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .accordion-button:not(.collapsed) {
  color: #ec407a;
  background: rgba(236, 64, 122, 0.1);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem transparentize($pc-secondary, 0.75);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .dropdown-item:hover,
[data-pc-theme=dark][data-pc-preset=preset-3] .dropdown-item:focus {
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: transparentize($pc-secondary, 0.8);
  color: var(--bs-dropdown-link-color);
  background: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item .nav-link.active, [data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item.show .nav-link,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item .nav-link.active,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item.show .nav-link {
  color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item .nav-link.active .material-icons-two-tone, [data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item .nav-link.active .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item.show .nav-link .material-icons-two-tone {
  background-color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item .nav-link.active::after, [data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item.show .nav-link::after,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item .nav-link.active::after,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item.show .nav-link::after {
  background: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item .nav-link:hover,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item .nav-link:hover {
  color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .kanban-tabs .nav-item .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-3] .profile-tabs .nav-item .nav-link:hover .material-icons-two-tone {
  background-color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .account-tabs .nav-item.show .nav-link h5,
[data-pc-theme=dark][data-pc-preset=preset-3] .account-tabs .nav-link:hover h5,
[data-pc-theme=dark][data-pc-preset=preset-3] .account-tabs .nav-link.active h5 {
  color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .account-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-3] .account-tabs .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-3] .account-tabs .nav-link.active .material-icons-two-tone {
  background-color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .mail-option .mail-buttons {
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .auth-main .auth-wrapper.v1 .auth-form {
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .auth-main .auth-wrapper.v2 .auth-sidecontent {
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .auth-main .auth-wrapper.v3 .auth-form {
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .auth-main .auth-wrapper .saprator:after {
  background: #1b295f;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .auth-main .auth-wrapper .saprator span {
  color: var(--bs-body-color);
  background: #101837;
  outline-color: #1b295f;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .price-card h2::after,
[data-pc-theme=dark][data-pc-preset=preset-3] .price-card .h2::after {
  background: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .price-card.active {
  border-color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-3].layout-2 {
  --pc-sidebar-background: #0a0f23;
  --pc-header-background: #0a0f23;
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3].layout-2 .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-3].layout-2 .pc-sidebar {
  background: #0a0f23;
}
[data-pc-theme=dark][data-pc-preset=preset-3].layout-2 .pc-container {
  background: #0d142f;
}
[data-pc-theme=dark][data-pc-preset=preset-3].layout-2 .pc-container .page-header,
[data-pc-theme=dark][data-pc-preset=preset-3].layout-2 .pc-container .card {
  background: #101837;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(32, 52, 97, 0.2);
  background-color: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .alert-primary {
  color: #203461;
  background: rgba(32, 52, 97, 0.2);
  border-color: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .alert-primary .alert-link {
  color: #203461;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .badge.bg-light-primary {
  background: rgba(32, 52, 97, 0.2);
  color: #203461;
  border-color: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .icon-svg-primary {
  fill: rgba(32, 52, 97, 0.2);
  stroke: #203461;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .bg-light-primary {
  background: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:not(:hover) {
  background: rgba(32, 52, 97, 0.2);
  border-color: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:hover {
  background: rgba(32, 52, 97, 0.2);
  border-color: rgba(32, 52, 97, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(236, 64, 122, 0.2);
  background-color: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .alert-secondary {
  color: #ec407a;
  background: rgba(236, 64, 122, 0.2);
  border-color: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .alert-secondary .alert-link {
  color: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .badge.bg-light-secondary {
  background: rgba(236, 64, 122, 0.2);
  color: #ec407a;
  border-color: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .icon-svg-secondary {
  fill: rgba(236, 64, 122, 0.2);
  stroke: #ec407a;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .bg-light-secondary {
  background: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-secondary:not(:hover) {
  background: rgba(236, 64, 122, 0.2);
  border-color: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-secondary:hover {
  background: rgba(236, 64, 122, 0.2);
  border-color: rgba(236, 64, 122, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] {
  --bs-dark-body-bg: #010606;
  --bs-primary: #16595a;
  --bs-body-bg: #010606;
  background: #051c1c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .bg-primary-dark {
  background: #104848;
  color: #104848;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .bg-secondary-dark {
  background: #ba6b1a;
  color: #ba6b1a;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .frameworks-card {
  background: #020f0f !important;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .testaments-cards::after {
  background: linear-gradient(0deg, #051c1c, transparent);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .dropdown-menu {
  --bs-dropdown-bg: #083232;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination a,
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination .datatable-active a, [data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination .datatable-active button,
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-pagination .datatable-active button:hover {
  background-color: #083232;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .offcanvas,
[data-pc-theme=dark][data-pc-preset=preset-4] .offcanvas-xxl,
[data-pc-theme=dark][data-pc-preset=preset-4] .offcanvas-xl,
[data-pc-theme=dark][data-pc-preset=preset-4] .offcanvas-lg,
[data-pc-theme=dark][data-pc-preset=preset-4] .offcanvas-md,
[data-pc-theme=dark][data-pc-preset=preset-4] .offcanvas-sm {
  --bs-offcanvas-bg: #051c1c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .pc-container {
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .bg-body {
  background: #010606 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .auth-wrapper.v2 {
  background: #051c1c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .form-control,
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-input,
[data-pc-theme=dark][data-pc-preset=preset-4] .sticky-action,
[data-pc-theme=dark][data-pc-preset=preset-4] .card:not([class*=bg-]),
[data-pc-theme=dark][data-pc-preset=preset-4] .page-header,
[data-pc-theme=dark][data-pc-preset=preset-4] .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-4] .pc-sidebar {
  background: #051c1c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .card {
  --bs-card-bg: lighten($pc-body, 5%);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .datatable-selector,
[data-pc-theme=dark][data-pc-preset=preset-4] .dataTable-selector,
[data-pc-theme=dark][data-pc-preset=preset-4] .custom-select,
[data-pc-theme=dark][data-pc-preset=preset-4] .form-select {
  background-color: #051c1c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .pct-customizer .pct-c-content {
  background: #052020;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .pct-customizer .pct-c-content {
  box-shadow: -1px 0 1px 0px rgba(1, 6, 6, 0.5);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-primary {
  background: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .pc-header .pc-head-link.head-link-secondary {
  background: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .pc-header .dropdown-notification .list-group-item-action:active, [data-pc-theme=dark][data-pc-preset=preset-4] .pc-header .dropdown-notification .list-group-item-action:hover, [data-pc-theme=dark][data-pc-preset=preset-4] .pc-header .dropdown-notification .list-group-item-action:focus {
  background: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  background: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu {
  background: #051c1c;
}
[data-pc-theme=dark][data-pc-preset=preset-4][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item.active > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-4][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:focus > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-4][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:hover > .pc-link {
  color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .page-link:hover {
  background: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .list-group {
  --bs-list-group-action-hover-bg: rgba(22, 89, 90, 0.2);
  --bs-list-group-action-hover-color: #16595a;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .modal {
  --bs-modal-bg: #051c1c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .toast {
  --bs-toast-bg: #051c1c;
  --bs-toast-header-bg: #062525;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .swal2-popup {
  background: #062525;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .accordion-button:not(.collapsed) {
  color: #c77e23;
  background: rgba(199, 126, 35, 0.1);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem transparentize($pc-secondary, 0.75);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .dropdown-item:hover,
[data-pc-theme=dark][data-pc-preset=preset-4] .dropdown-item:focus {
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: transparentize($pc-secondary, 0.8);
  color: var(--bs-dropdown-link-color);
  background: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item .nav-link.active, [data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item.show .nav-link,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item .nav-link.active,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item.show .nav-link {
  color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item .nav-link.active .material-icons-two-tone, [data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item .nav-link.active .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item.show .nav-link .material-icons-two-tone {
  background-color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item .nav-link.active::after, [data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item.show .nav-link::after,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item .nav-link.active::after,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item.show .nav-link::after {
  background: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item .nav-link:hover,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item .nav-link:hover {
  color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .kanban-tabs .nav-item .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-4] .profile-tabs .nav-item .nav-link:hover .material-icons-two-tone {
  background-color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .account-tabs .nav-item.show .nav-link h5,
[data-pc-theme=dark][data-pc-preset=preset-4] .account-tabs .nav-link:hover h5,
[data-pc-theme=dark][data-pc-preset=preset-4] .account-tabs .nav-link.active h5 {
  color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .account-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-4] .account-tabs .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-4] .account-tabs .nav-link.active .material-icons-two-tone {
  background-color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .mail-option .mail-buttons {
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .auth-main .auth-wrapper.v1 .auth-form {
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .auth-main .auth-wrapper.v2 .auth-sidecontent {
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .auth-main .auth-wrapper.v3 .auth-form {
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .auth-main .auth-wrapper .saprator:after {
  background: #0c4848;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .auth-main .auth-wrapper .saprator span {
  color: var(--bs-body-color);
  background: #051c1c;
  outline-color: #0c4848;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .price-card h2::after,
[data-pc-theme=dark][data-pc-preset=preset-4] .price-card .h2::after {
  background: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .price-card.active {
  border-color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-4].layout-2 {
  --pc-sidebar-background: #010606;
  --pc-header-background: #010606;
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4].layout-2 .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-4].layout-2 .pc-sidebar {
  background: #010606;
}
[data-pc-theme=dark][data-pc-preset=preset-4].layout-2 .pc-container {
  background: #031313;
}
[data-pc-theme=dark][data-pc-preset=preset-4].layout-2 .pc-container .page-header,
[data-pc-theme=dark][data-pc-preset=preset-4].layout-2 .pc-container .card {
  background: #051c1c;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(22, 89, 90, 0.2);
  background-color: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .alert-primary {
  color: #16595a;
  background: rgba(22, 89, 90, 0.2);
  border-color: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .alert-primary .alert-link {
  color: #16595a;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .badge.bg-light-primary {
  background: rgba(22, 89, 90, 0.2);
  color: #16595a;
  border-color: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .icon-svg-primary {
  fill: rgba(22, 89, 90, 0.2);
  stroke: #16595a;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .bg-light-primary {
  background: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:not(:hover) {
  background: rgba(22, 89, 90, 0.2);
  border-color: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:hover {
  background: rgba(22, 89, 90, 0.2);
  border-color: rgba(22, 89, 90, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(199, 126, 35, 0.2);
  background-color: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .alert-secondary {
  color: #c77e23;
  background: rgba(199, 126, 35, 0.2);
  border-color: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .alert-secondary .alert-link {
  color: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .badge.bg-light-secondary {
  background: rgba(199, 126, 35, 0.2);
  color: #c77e23;
  border-color: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .icon-svg-secondary {
  fill: rgba(199, 126, 35, 0.2);
  stroke: #c77e23;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .bg-light-secondary {
  background: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-secondary:not(:hover) {
  background: rgba(199, 126, 35, 0.2);
  border-color: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-secondary:hover {
  background: rgba(199, 126, 35, 0.2);
  border-color: rgba(199, 126, 35, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] {
  --bs-dark-body-bg: #030708;
  --bs-primary: #173e43;
  --bs-body-bg: #030708;
  background: #0a171b;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .bg-primary-dark {
  background: #113034;
  color: #113034;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .bg-secondary-dark {
  background: #31a09b;
  color: #31a09b;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .frameworks-card {
  background: #060d0f !important;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .testaments-cards::after {
  background: linear-gradient(0deg, #0a171b, transparent);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .dropdown-menu {
  --bs-dropdown-bg: #11272d;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination a,
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination .datatable-active a, [data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination .datatable-active button,
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-pagination .datatable-active button:hover {
  background-color: #11272d;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .offcanvas,
[data-pc-theme=dark][data-pc-preset=preset-5] .offcanvas-xxl,
[data-pc-theme=dark][data-pc-preset=preset-5] .offcanvas-xl,
[data-pc-theme=dark][data-pc-preset=preset-5] .offcanvas-lg,
[data-pc-theme=dark][data-pc-preset=preset-5] .offcanvas-md,
[data-pc-theme=dark][data-pc-preset=preset-5] .offcanvas-sm {
  --bs-offcanvas-bg: #0a171b;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .pc-container {
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .bg-body {
  background: #030708 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .auth-wrapper.v2 {
  background: #0a171b;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .form-control,
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-input,
[data-pc-theme=dark][data-pc-preset=preset-5] .sticky-action,
[data-pc-theme=dark][data-pc-preset=preset-5] .card:not([class*=bg-]),
[data-pc-theme=dark][data-pc-preset=preset-5] .page-header,
[data-pc-theme=dark][data-pc-preset=preset-5] .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-5] .pc-sidebar {
  background: #0a171b;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .card {
  --bs-card-bg: lighten($pc-body, 5%);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .datatable-selector,
[data-pc-theme=dark][data-pc-preset=preset-5] .dataTable-selector,
[data-pc-theme=dark][data-pc-preset=preset-5] .custom-select,
[data-pc-theme=dark][data-pc-preset=preset-5] .form-select {
  background-color: #0a171b;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .pct-customizer .pct-c-content {
  background: #0b1a1e;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .pct-customizer .pct-c-content {
  box-shadow: -1px 0 1px 0px rgba(3, 7, 8, 0.5);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-primary {
  background: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .pc-header .pc-head-link.head-link-secondary {
  background: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .pc-header .dropdown-notification .list-group-item-action:active, [data-pc-theme=dark][data-pc-preset=preset-5] .pc-header .dropdown-notification .list-group-item-action:hover, [data-pc-theme=dark][data-pc-preset=preset-5] .pc-header .dropdown-notification .list-group-item-action:focus {
  background: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  background: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu {
  background: #0a171b;
}
[data-pc-theme=dark][data-pc-preset=preset-5][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item.active > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-5][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:focus > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-5][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:hover > .pc-link {
  color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .page-link:hover {
  background: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .list-group {
  --bs-list-group-action-hover-bg: rgba(23, 62, 67, 0.2);
  --bs-list-group-action-hover-color: #173e43;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .modal {
  --bs-modal-bg: #0a171b;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .toast {
  --bs-toast-bg: #0a171b;
  --bs-toast-header-bg: #0d1e22;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .swal2-popup {
  background: #0d1e22;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .accordion-button:not(.collapsed) {
  color: #3fb0ac;
  background: rgba(63, 176, 172, 0.1);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem transparentize($pc-secondary, 0.75);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .dropdown-item:hover,
[data-pc-theme=dark][data-pc-preset=preset-5] .dropdown-item:focus {
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: transparentize($pc-secondary, 0.8);
  color: var(--bs-dropdown-link-color);
  background: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item .nav-link.active, [data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item.show .nav-link,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item .nav-link.active,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item.show .nav-link {
  color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item .nav-link.active .material-icons-two-tone, [data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item .nav-link.active .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item.show .nav-link .material-icons-two-tone {
  background-color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item .nav-link.active::after, [data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item.show .nav-link::after,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item .nav-link.active::after,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item.show .nav-link::after {
  background: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item .nav-link:hover,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item .nav-link:hover {
  color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .kanban-tabs .nav-item .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-5] .profile-tabs .nav-item .nav-link:hover .material-icons-two-tone {
  background-color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .account-tabs .nav-item.show .nav-link h5,
[data-pc-theme=dark][data-pc-preset=preset-5] .account-tabs .nav-link:hover h5,
[data-pc-theme=dark][data-pc-preset=preset-5] .account-tabs .nav-link.active h5 {
  color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .account-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-5] .account-tabs .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-5] .account-tabs .nav-link.active .material-icons-two-tone {
  background-color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .mail-option .mail-buttons {
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .auth-main .auth-wrapper.v1 .auth-form {
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .auth-main .auth-wrapper.v2 .auth-sidecontent {
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .auth-main .auth-wrapper.v3 .auth-form {
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .auth-main .auth-wrapper .saprator:after {
  background: #183840;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .auth-main .auth-wrapper .saprator span {
  color: var(--bs-body-color);
  background: #0a171b;
  outline-color: #183840;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .price-card h2::after,
[data-pc-theme=dark][data-pc-preset=preset-5] .price-card .h2::after {
  background: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .price-card.active {
  border-color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-5].layout-2 {
  --pc-sidebar-background: #030708;
  --pc-header-background: #030708;
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5].layout-2 .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-5].layout-2 .pc-sidebar {
  background: #030708;
}
[data-pc-theme=dark][data-pc-preset=preset-5].layout-2 .pc-container {
  background: #071113;
}
[data-pc-theme=dark][data-pc-preset=preset-5].layout-2 .pc-container .page-header,
[data-pc-theme=dark][data-pc-preset=preset-5].layout-2 .pc-container .card {
  background: #0a171b;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(23, 62, 67, 0.2);
  background-color: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .alert-primary {
  color: #173e43;
  background: rgba(23, 62, 67, 0.2);
  border-color: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .alert-primary .alert-link {
  color: #173e43;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .badge.bg-light-primary {
  background: rgba(23, 62, 67, 0.2);
  color: #173e43;
  border-color: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .icon-svg-primary {
  fill: rgba(23, 62, 67, 0.2);
  stroke: #173e43;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .bg-light-primary {
  background: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:not(:hover) {
  background: rgba(23, 62, 67, 0.2);
  border-color: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:hover {
  background: rgba(23, 62, 67, 0.2);
  border-color: rgba(23, 62, 67, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(63, 176, 172, 0.2);
  background-color: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .alert-secondary {
  color: #3fb0ac;
  background: rgba(63, 176, 172, 0.2);
  border-color: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .alert-secondary .alert-link {
  color: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .badge.bg-light-secondary {
  background: rgba(63, 176, 172, 0.2);
  color: #3fb0ac;
  border-color: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .icon-svg-secondary {
  fill: rgba(63, 176, 172, 0.2);
  stroke: #3fb0ac;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .bg-light-secondary {
  background: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-secondary:not(:hover) {
  background: rgba(63, 176, 172, 0.2);
  border-color: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-secondary:hover {
  background: rgba(63, 176, 172, 0.2);
  border-color: rgba(63, 176, 172, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] {
  --bs-dark-body-bg: #051327;
  --bs-primary: #0a2342;
  --bs-body-bg: #051327;
  background: #081e3e;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .bg-primary-dark {
  background: #071a33;
  color: #071a33;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .bg-secondary-dark {
  background: #21937a;
  color: #21937a;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .frameworks-card {
  background: #061730 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .testaments-cards::after {
  background: linear-gradient(0deg, #081e3e, transparent);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .dropdown-menu {
  --bs-dropdown-bg: #0b2954;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination a,
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination .datatable-active a, [data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination .datatable-active button,
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-pagination .datatable-active button:hover {
  background-color: #0b2954;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .offcanvas,
[data-pc-theme=dark][data-pc-preset=preset-6] .offcanvas-xxl,
[data-pc-theme=dark][data-pc-preset=preset-6] .offcanvas-xl,
[data-pc-theme=dark][data-pc-preset=preset-6] .offcanvas-lg,
[data-pc-theme=dark][data-pc-preset=preset-6] .offcanvas-md,
[data-pc-theme=dark][data-pc-preset=preset-6] .offcanvas-sm {
  --bs-offcanvas-bg: #081e3e;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .pc-container {
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .bg-body {
  background: #051327 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .auth-wrapper.v2 {
  background: #081e3e;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .form-control,
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-input,
[data-pc-theme=dark][data-pc-preset=preset-6] .sticky-action,
[data-pc-theme=dark][data-pc-preset=preset-6] .card:not([class*=bg-]),
[data-pc-theme=dark][data-pc-preset=preset-6] .page-header,
[data-pc-theme=dark][data-pc-preset=preset-6] .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-6] .pc-sidebar {
  background: #081e3e;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .card {
  --bs-card-bg: lighten($pc-body, 5%);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .datatable-selector,
[data-pc-theme=dark][data-pc-preset=preset-6] .dataTable-selector,
[data-pc-theme=dark][data-pc-preset=preset-6] .custom-select,
[data-pc-theme=dark][data-pc-preset=preset-6] .form-select {
  background-color: #081e3e;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .pct-customizer .pct-c-content {
  background: #082042;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .pct-customizer .pct-c-content {
  box-shadow: -1px 0 1px 0px rgba(5, 19, 39, 0.5);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-primary {
  background: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .pc-header .pc-head-link.head-link-secondary {
  background: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .pc-header .dropdown-notification .list-group-item-action:active, [data-pc-theme=dark][data-pc-preset=preset-6] .pc-header .dropdown-notification .list-group-item-action:hover, [data-pc-theme=dark][data-pc-preset=preset-6] .pc-header .dropdown-notification .list-group-item-action:focus {
  background: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  background: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu {
  background: #081e3e;
}
[data-pc-theme=dark][data-pc-preset=preset-6][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item.active > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-6][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:focus > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-6][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:hover > .pc-link {
  color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .page-link:hover {
  background: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .list-group {
  --bs-list-group-action-hover-bg: rgba(10, 35, 66, 0.2);
  --bs-list-group-action-hover-color: #0a2342;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .modal {
  --bs-modal-bg: #081e3e;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .toast {
  --bs-toast-bg: #081e3e;
  --bs-toast-header-bg: #092247;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .swal2-popup {
  background: #092247;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .accordion-button:not(.collapsed) {
  color: #2ca58d;
  background: rgba(44, 165, 141, 0.1);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem transparentize($pc-secondary, 0.75);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .dropdown-item:hover,
[data-pc-theme=dark][data-pc-preset=preset-6] .dropdown-item:focus {
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: transparentize($pc-secondary, 0.8);
  color: var(--bs-dropdown-link-color);
  background: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item .nav-link.active, [data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item.show .nav-link,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item .nav-link.active,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item.show .nav-link {
  color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item .nav-link.active .material-icons-two-tone, [data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item .nav-link.active .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item.show .nav-link .material-icons-two-tone {
  background-color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item .nav-link.active::after, [data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item.show .nav-link::after,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item .nav-link.active::after,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item.show .nav-link::after {
  background: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item .nav-link:hover,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item .nav-link:hover {
  color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .kanban-tabs .nav-item .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-6] .profile-tabs .nav-item .nav-link:hover .material-icons-two-tone {
  background-color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .account-tabs .nav-item.show .nav-link h5,
[data-pc-theme=dark][data-pc-preset=preset-6] .account-tabs .nav-link:hover h5,
[data-pc-theme=dark][data-pc-preset=preset-6] .account-tabs .nav-link.active h5 {
  color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .account-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-6] .account-tabs .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-6] .account-tabs .nav-link.active .material-icons-two-tone {
  background-color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .mail-option .mail-buttons {
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .auth-main .auth-wrapper.v1 .auth-form {
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .auth-main .auth-wrapper.v2 .auth-sidecontent {
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .auth-main .auth-wrapper.v3 .auth-form {
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .auth-main .auth-wrapper .saprator:after {
  background: #0e346b;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .auth-main .auth-wrapper .saprator span {
  color: var(--bs-body-color);
  background: #081e3e;
  outline-color: #0e346b;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .price-card h2::after,
[data-pc-theme=dark][data-pc-preset=preset-6] .price-card .h2::after {
  background: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .price-card.active {
  border-color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-6].layout-2 {
  --pc-sidebar-background: #051327;
  --pc-header-background: #051327;
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6].layout-2 .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-6].layout-2 .pc-sidebar {
  background: #051327;
}
[data-pc-theme=dark][data-pc-preset=preset-6].layout-2 .pc-container {
  background: #071a35;
}
[data-pc-theme=dark][data-pc-preset=preset-6].layout-2 .pc-container .page-header,
[data-pc-theme=dark][data-pc-preset=preset-6].layout-2 .pc-container .card {
  background: #081e3e;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(10, 35, 66, 0.2);
  background-color: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .alert-primary {
  color: #0a2342;
  background: rgba(10, 35, 66, 0.2);
  border-color: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .alert-primary .alert-link {
  color: #0a2342;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .badge.bg-light-primary {
  background: rgba(10, 35, 66, 0.2);
  color: #0a2342;
  border-color: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .icon-svg-primary {
  fill: rgba(10, 35, 66, 0.2);
  stroke: #0a2342;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .bg-light-primary {
  background: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:not(:hover) {
  background: rgba(10, 35, 66, 0.2);
  border-color: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:hover {
  background: rgba(10, 35, 66, 0.2);
  border-color: rgba(10, 35, 66, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(44, 165, 141, 0.2);
  background-color: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .alert-secondary {
  color: #2ca58d;
  background: rgba(44, 165, 141, 0.2);
  border-color: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .alert-secondary .alert-link {
  color: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .badge.bg-light-secondary {
  background: rgba(44, 165, 141, 0.2);
  color: #2ca58d;
  border-color: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .icon-svg-secondary {
  fill: rgba(44, 165, 141, 0.2);
  stroke: #2ca58d;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .bg-light-secondary {
  background: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-secondary:not(:hover) {
  background: rgba(44, 165, 141, 0.2);
  border-color: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-secondary:hover {
  background: rgba(44, 165, 141, 0.2);
  border-color: rgba(44, 165, 141, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] {
  --bs-dark-body-bg: #1a223f;
  --bs-primary: #3f51b5;
  --bs-body-bg: #1a223f;
  background: #212c51;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .bg-primary-dark {
  background: #3140a5;
  color: #3140a5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .bg-secondary-dark {
  background: #3140a5;
  color: #3140a5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .frameworks-card {
  background: #1d2646 !important;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .testaments-cards::after {
  background: linear-gradient(0deg, #212c51, transparent);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .dropdown-menu {
  --bs-dropdown-bg: #293563;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination a,
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination .datatable-active a, [data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination .datatable-active button,
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-pagination .datatable-active button:hover {
  background-color: #293563;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .offcanvas,
[data-pc-theme=dark][data-pc-preset=preset-7] .offcanvas-xxl,
[data-pc-theme=dark][data-pc-preset=preset-7] .offcanvas-xl,
[data-pc-theme=dark][data-pc-preset=preset-7] .offcanvas-lg,
[data-pc-theme=dark][data-pc-preset=preset-7] .offcanvas-md,
[data-pc-theme=dark][data-pc-preset=preset-7] .offcanvas-sm {
  --bs-offcanvas-bg: #212c51;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .pc-container {
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .bg-body {
  background: #1a223f !important;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .auth-wrapper.v2 {
  background: #212c51;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .form-control,
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-input,
[data-pc-theme=dark][data-pc-preset=preset-7] .sticky-action,
[data-pc-theme=dark][data-pc-preset=preset-7] .card:not([class*=bg-]),
[data-pc-theme=dark][data-pc-preset=preset-7] .page-header,
[data-pc-theme=dark][data-pc-preset=preset-7] .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-7] .pc-sidebar {
  background: #212c51;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .card {
  --bs-card-bg: lighten($pc-body, 5%);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .datatable-selector,
[data-pc-theme=dark][data-pc-preset=preset-7] .dataTable-selector,
[data-pc-theme=dark][data-pc-preset=preset-7] .custom-select,
[data-pc-theme=dark][data-pc-preset=preset-7] .form-select {
  background-color: #212c51;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .pct-customizer .pct-c-content {
  background: #232e55;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .pct-customizer .pct-c-content {
  box-shadow: -1px 0 1px 0px rgba(26, 34, 63, 0.5);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-primary {
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .pc-header .pc-head-link.head-link-secondary {
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .pc-header .dropdown-notification .list-group-item-action:active, [data-pc-theme=dark][data-pc-preset=preset-7] .pc-header .dropdown-notification .list-group-item-action:hover, [data-pc-theme=dark][data-pc-preset=preset-7] .pc-header .dropdown-notification .list-group-item-action:focus {
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu {
  background: #212c51;
}
[data-pc-theme=dark][data-pc-preset=preset-7][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item.active > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-7][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:focus > .pc-link, [data-pc-theme=dark][data-pc-preset=preset-7][data-pc-layout=horizontal] .pc-sidebar .pc-navbar .pc-submenu .pc-item:hover > .pc-link {
  color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .page-link:hover {
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .list-group {
  --bs-list-group-action-hover-bg: rgba(63, 81, 181, 0.2);
  --bs-list-group-action-hover-color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .modal {
  --bs-modal-bg: #212c51;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .toast {
  --bs-toast-bg: #212c51;
  --bs-toast-header-bg: #243058;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .swal2-popup {
  background: #243058;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .accordion-button:not(.collapsed) {
  color: #3f51b5;
  background: rgba(63, 81, 181, 0.1);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem transparentize($pc-secondary, 0.75);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .dropdown-item:hover,
[data-pc-theme=dark][data-pc-preset=preset-7] .dropdown-item:focus {
  --bs-dropdown-link-hover-color: var(--bs-dropdown-link-color);
  --bs-dropdown-link-hover-bg: transparentize($pc-secondary, 0.8);
  color: var(--bs-dropdown-link-color);
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item .nav-link.active, [data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item.show .nav-link,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item .nav-link.active,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item.show .nav-link {
  color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item .nav-link.active .material-icons-two-tone, [data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item .nav-link.active .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item.show .nav-link .material-icons-two-tone {
  background-color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item .nav-link.active::after, [data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item.show .nav-link::after,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item .nav-link.active::after,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item.show .nav-link::after {
  background: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item .nav-link:hover,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item .nav-link:hover {
  color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .kanban-tabs .nav-item .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-7] .profile-tabs .nav-item .nav-link:hover .material-icons-two-tone {
  background-color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .account-tabs .nav-item.show .nav-link h5,
[data-pc-theme=dark][data-pc-preset=preset-7] .account-tabs .nav-link:hover h5,
[data-pc-theme=dark][data-pc-preset=preset-7] .account-tabs .nav-link.active h5 {
  color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .account-tabs .nav-item.show .nav-link .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-7] .account-tabs .nav-link:hover .material-icons-two-tone,
[data-pc-theme=dark][data-pc-preset=preset-7] .account-tabs .nav-link.active .material-icons-two-tone {
  background-color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .mail-option .mail-buttons {
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .auth-main .auth-wrapper.v1 .auth-form {
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .auth-main .auth-wrapper.v2 .auth-sidecontent {
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .auth-main .auth-wrapper.v3 .auth-form {
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .auth-main .auth-wrapper .saprator:after {
  background: #303f75;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .auth-main .auth-wrapper .saprator span {
  color: var(--bs-body-color);
  background: #212c51;
  outline-color: #303f75;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .price-card h2::after,
[data-pc-theme=dark][data-pc-preset=preset-7] .price-card .h2::after {
  background: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .price-card.active {
  border-color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .navbar.navbar-light .navbar-toggler-icon {
  filter: invert(1) grayscale(100%) brightness(200%);
}
[data-pc-theme=dark][data-pc-preset=preset-7].layout-2 {
  --pc-sidebar-background: #1a223f;
  --pc-header-background: #1a223f;
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7].layout-2 .pc-header,
[data-pc-theme=dark][data-pc-preset=preset-7].layout-2 .pc-sidebar {
  background: #1a223f;
}
[data-pc-theme=dark][data-pc-preset=preset-7].layout-2 .pc-container {
  background: #1e284a;
}
[data-pc-theme=dark][data-pc-preset=preset-7].layout-2 .pc-container .page-header,
[data-pc-theme=dark][data-pc-preset=preset-7].layout-2 .pc-container .card {
  background: #212c51;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(63, 81, 181, 0.2);
  background-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .alert-primary {
  color: #3f51b5;
  background: rgba(63, 81, 181, 0.2);
  border-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .alert-primary .alert-link {
  color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .badge.bg-light-primary {
  background: rgba(63, 81, 181, 0.2);
  color: #3f51b5;
  border-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .icon-svg-primary {
  fill: rgba(63, 81, 181, 0.2);
  stroke: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .bg-light-primary {
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:not(:hover) {
  background: rgba(63, 81, 181, 0.2);
  border-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:hover {
  background: rgba(63, 81, 181, 0.2);
  border-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(63, 81, 181, 0.2);
  background-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .alert-secondary {
  color: #3f51b5;
  background: rgba(63, 81, 181, 0.2);
  border-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .alert-secondary .alert-link {
  color: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .badge.bg-light-secondary {
  background: rgba(63, 81, 181, 0.2);
  color: #3f51b5;
  border-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .icon-svg-secondary {
  fill: rgba(63, 81, 181, 0.2);
  stroke: #3f51b5;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .bg-light-secondary {
  background: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-secondary:not(:hover) {
  background: rgba(63, 81, 181, 0.2);
  border-color: rgba(63, 81, 181, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-secondary:hover {
  background: rgba(63, 81, 181, 0.2);
  border-color: rgba(63, 81, 181, 0.2);
}